<template>
  <div>
    <div class="lg fontsize">
      <div class="fontsize-title">售后服务</div>
      <br />
      我们致力于为您提供优质的服务，并为您提供满意的售后保障。在收到商品后，请您及时检查商品的完好性，若您遇到问题需要解决，请您仔细阅读以下售后服务说明：
      <br /> <br />
      <div style="font-weight: 600;">
        本网站不支持退货和换款，请谨慎购买。 <br />
        本网站支持更换同款尺码或质量原因需要更换同款产品。

      </div>
      <br /> <br />


      1、更换条件 <br />
      （1）时间限制：商品在签收48小时内，如需更换尺码或者发现有质量问题，请及时联系客服，逾期不予处理。<br />
      （2）商品状态：退回时商品应保持原始状态，未经使用、未经洗涤、未经损坏，且附件、赠品等齐全，请勿影响二次销售。<br />
      （3）质量原因：若商品存在质量问题，如破损、污渍等，请第一时间拍照联系客服进行情况反馈和说明。<br />
      （4）快递运输：快递原因导致的破损或者掉件，请及时联系客服进行情况反馈。<br /> <br />

      2、更换流程<br />
      （1）联系客服：您需要通过在线客服或电子邮件与我们取得联系，告知更换的原因和需求。<br />
      （2）确认信息：您需要提供相关的订单信息、商品照片以及更换原因等，经过客服团队进行核实，以确定是否符合更换的条件。<br />
      （3）返还商品：将需要换码的商品寄回。请确保您记录好寄件时的快递单号，以便后续查询。<br />
      （4）检查和处理：我们收到您寄回的商品后，会对商品进行检查，确保商品 <span style="font-weight: 600;">无损、无味、吊牌包装完好。</span><br />
      如果一切符合要求，我们将安排发送新的商品给您，或者进行其他协商好的解决方案。<br />
      （5）快递说明：您需要自行承担更换商品尺码的往返邮费。若核定为商品存在质量问题或我们责任导致的换货，我们承担往返邮费，请勿发“到付件”。<br /> <br />

      3、注意事项<br />
      --在换码过程中，请双方保持良好沟通，并提供准确、清晰的信息以便我们快速处理您的诉求。<br />
      --请确保您退回商品处于原始状态，并妥善包装，以避免在运输过程中造成损坏，影响商品的二次销售。<br />
      --请确保您退回包裹里面有收件人信息、换码或其他需求。<br />
      --请确保您记录好返件物流单号，并关注物流信息以便了解商品退回的进度并及时提醒我们。<br />
      <span style="font-weight: 600;">
        --选购时，请您根据网站产品参数、个人穿衣习惯、自身三围数据和产品版型来选择尺码。
      </span><br /> <br />


      4、联系方式<br />
      如有需求，请您联系在线客服或发送电子邮箱：seooocookie2013@163.com<br /> <br />

      5、工作时间<br />
      周一至周六，9:00 - 17:00（法定节假日除外）<br /> <br />

      以上为售后服务的简要说明，如果您有任何疑问或需要进一步的帮助，请随时联系我们的客服团队。再次感谢您对seooocookie的喜爱和支持！
      <br /> <br />


    </div>
    <div class="zrl zrlcontent zrlfontsize">
      <div class="zrlfontsize-title">售后服务</div>
      <br />
      我们致力于为您提供优质的服务，并为您提供满意的售后保障。在收到商品后，请您及时检查商品的完好性，若您遇到问题需要解决，请您仔细阅读以下售后服务说明：
      <br /> <br />
      <div style="font-weight: 600;">
        本网站不支持退货和换款，请谨慎购买。 <br />
        本网站支持更换同款尺码或质量原因需要更换同款产品。

      </div>
      <br /> <br />


      1、更换条件 <br />
      （1）时间限制：商品在签收48小时内，如需更换尺码或者发现有质量问题，请及时联系客服，逾期不予处理。<br />
      （2）商品状态：退回时商品应保持原始状态，未经使用、未经洗涤、未经损坏，且附件、赠品等齐全，请勿影响二次销售。<br />
      （3）质量原因：若商品存在质量问题，如破损、污渍等，请第一时间拍照联系客服进行情况反馈和说明。<br />
      （4）快递运输：快递原因导致的破损或者掉件，请及时联系客服进行情况反馈。<br /> <br />

      2、更换流程<br />
      （1）联系客服：您需要通过在线客服或电子邮件与我们取得联系，告知更换的原因和需求。<br />
      （2）确认信息：您需要提供相关的订单信息、商品照片以及更换原因等，经过客服团队进行核实，以确定是否符合更换的条件。<br />
      （3）返还商品：将需要换码的商品寄回。请确保您记录好寄件时的快递单号，以便后续查询。<br />
      （4）检查和处理：我们收到您寄回的商品后，会对商品进行检查，确保商品 <span style="font-weight: 600;">无损、无味、吊牌包装完好。</span><br />
      如果一切符合要求，我们将安排发送新的商品给您，或者进行其他协商好的解决方案。<br />
      （5）快递说明：您需要自行承担更换商品尺码的往返邮费。若核定为商品存在质量问题或我们责任导致的换货，我们承担往返邮费，请勿发“到付件”。<br /> <br />

      3、注意事项<br />
      --在换码过程中，请双方保持良好沟通，并提供准确、清晰的信息以便我们快速处理您的诉求。<br />
      --请确保您退回商品处于原始状态，并妥善包装，以避免在运输过程中造成损坏，影响商品的二次销售。<br />
      --请确保您退回包裹里面有收件人信息、换码或其他需求。<br />
      --请确保您记录好返件物流单号，并关注物流信息以便了解商品退回的进度并及时提醒我们。<br />
      <span style="font-weight: 600;">
        --选购时，请您根据网站产品参数、个人穿衣习惯、自身三围数据和产品版型来选择尺码。
      </span><br /> <br />


      4、联系方式<br />
      如有需求，请您联系在线客服或发送电子邮箱：seooocookie2013@163.com<br /> <br />

      5、工作时间<br />
      周一至周六，9:00 - 17:00（法定节假日除外）<br /> <br />

      以上为售后服务的简要说明，如果您有任何疑问或需要进一步的帮助，请随时联系我们的客服团队。再次感谢您对seooocookie的喜爱和支持！
      <br /> <br />


    </div>
  </div>
</template>

<script>
import Logodia from "../../components/Logodia.vue";
export default {
  components: {
    Logodia
  }
};
</script>

<style lang="less" scoped>
.fontsize {
  font-size: 24px;
  font-family: PingFang;
  padding: 30px;
  letter-spacing: 2px;
  line-height: 45px;
}

.fontsize-title {
  text-align: center;
  font-weight: 700;
}

.zrlfontsize {
  font-size: 16px;
  font-family: PingFang;
  padding: 30px;
  letter-spacing: 2px;
  line-height: 45px;
  width: 750px;
  margin:0 auto;
}

.zrlfontsize-title {
  text-align: center;
  font-weight: 700;
}
.zrlcontent{
  display: none;
}
@media screen and (min-width: 1000px) {
  .lg{
    display: none;
  }
  .zrlcontent{
    display: block;
  }
}
</style>
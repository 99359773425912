<template>
  <div>
    <!-- <Categorylist></Categorylist> -->
    <div v-if="!searchData.productCateId && showBaner">
      <van-swipe class="my-swipe" :autoplay="3000" duration="2000" indicator-color="white">
        <van-swipe-item v-for="(item, index) in bannerList" :key="index" @click="showBaner = false">
          <div class="swipeimgbox">
            <img class="swipeimg" src="../../assets/imgs/huabg.png" alt />
          </div>
        </van-swipe-item>
      </van-swipe>
    </div>

    <div class="zhanwei" v-if="!showBaner"></div>
    <!-- <div class="logobox">
      <img
        class="logoimg"
        @click="changeCategory('')"
        src="@/assets/imgs/logo@3x.png"
        alt=""
      />
    </div>-->

    <div class="categorybox" v-if="!showBaner">
      <div class="categoryitembox" :class="[categoryId == item.id ? 'checkitembox' : '']" v-for="item in categoryList"
        :key="item.id" @click="changeCategory(item)">{{ item.cateName }}</div>
    </div>
    <div class="zrl zrlcategorybox" v-if="!showBaner">
      <div class="zrlcategoryitembox" :class="[categoryId == item.id ? 'zrlcheckitembox' : '']"
        v-for="item in categoryList" :key="item.id" @click="changeCategory(item)">{{ item.cateName }}</div>
    </div>

    <!-- <div v-if="!searchData.productCateId &&showBaner">
      <van-swipe class="my-swipe" :autoplay="3000" indicator-color="white">
        <van-swipe-item v-for="(item, index) in bannerList" :key="index" @click="chooseSwipe(item)">
          <img class="swipeimg" v-lazy="item.url" />
        </van-swipe-item>
      </van-swipe>
    </div>-->
    <div class="mainheadbox" v-if="searchData.productCateId && !showBaner">{{ searchCategoryInfo.cateName }}</div>
    <div class="mainbox" v-if="!showBaner">
      <div class="mainitembox" v-for="item in productList" :key="item.id" @click="toCommoditydetails(item.id)">
        <img class="mainimg" :src="item.productMainImage" alt />
        <div class="main-name-box">{{ item.productName }}</div>
        <div class="main-price-box">￥{{ item.unitPrice }}</div>
        <div>
          <div class="main-tag-box num0" v-if="item.totalunit == 0">已售罄</div>
          <div class="main-tag-box" v-if="item.isQuantity == 1">限量款</div>
        </div>
      </div>
    </div>

    <div class="zrl zrlmainbox" v-if="!showBaner">
      <div class="zrlmainitembox" v-for="item in productList" :key="item.id" @click="toCommoditydetails(item.id)">
        <div style="position: relative;">
          <img class="zrlmainimg" :src="item.productMainImage" alt />
          <div class="zrlmain-tag-box" v-if="item.totalunit == 0">已售罄</div>
        </div>
        <div class="zrlmain-name-box">{{ item.productName }} </div>
        <div style="text-align: center;">
          <div
            style="border: 1px solid #bbbbbb;display: inline;padding: 2px 6px;font-size: 11px;margin-left: 10px;color: #7c7d7d;text-align: center; "
            v-if="item.isQuantity == 1">限量款</div>
        </div>
        <div class="zrlmain-price-box">￥{{ item.unitPrice }}</div>

      </div>
    </div>
    <!-- <div class="main-foot-box">(1/30)</div> -->
    <div class="margintop10" v-if="!showBaner">
      <!-- <van-pagination v-model="searchData.pageNumber" @change="changePageNumber" :total-items="total"
        :items-per-page="searchData.pageSize" mode="simple" /> -->
      <el-pagination layout="prev, pager, next" :total="total" :pager-count="5" :page-size.sync="searchData.pageSize"
        :current-page="searchData.pageNumber" @current-change="changePageNumber">
      </el-pagination>
    </div>
  </div>
</template>

<script>
import Categorylist from "../../components/Categorylist.vue";
import { productList_api } from "../../apis/product";
import { BannerList_api, categoryList_api } from "../../apis/layout";

export default {
  components: {
    Categorylist
  },
  data() {
    return {
      images: [
        { id: 1, image: "https://img01.yzcdn.cn/vant/apple-1.jpg" },
        { id: 2, image: "https://img01.yzcdn.cn/vant/apple-2.jpg" },
        { id: 3, image: "https://img01.yzcdn.cn/vant/apple-3.jpg" }
      ],
      searchData: {
        pageNumber: 1,
        pageSize: 18,
        productCateId: "",
        key: ""
      },
      productList: [],
      total: 0,
      bannerList: [],
      searchCategoryInfo: "",
      categoryList: [],
      categoryId: "",
      showBaner: true
    };
  },
  created() {
    console.log(this.$route.query);
    if (this.$route.query.searchValue) {
      this.searchData.key = this.$route.query.searchValue;
      this.searchData.productCateId = ""
      this.categoryId = ""
    } else {
      this.searchData.key = "";
    }

    this.searchData.productCateId = this.$route.query.searchCategoryId;
    this.categoryId = this.$route.query.searchCategoryId;
    this.searchFn();
    this.getBannerList();
    this.getCategoryList();

  },
  mounted() {
    this.$bus.$on("searchCategoryInfo", e => {
      this.searchCategoryInfo = e;
      this.searchData.productCateId = e.id;
      this.searchFn();
      this.getBannerList();
    });
  },
  methods: {
    searchFn() {
      this.searchData.pageNumber = 1;
      this.getProductList();

    },
    searchFntwo(val) {
      this.searchData.key = val;
      this.searchData.productCateId = ""
      this.categoryId = ""
      this.getProductList();
      this.showBaner = false
    },

    searchFnThree(id) {

      this.searchData.productCateId = id;
      this.searchData.key = "";
      this.categoryId = id;
      this.showBaner = false
      this.getProductList();
    },
    async getProductList() {
      window.scrollTo(0, 0)
      let { code, data } = await productList_api(this.searchData);
      if (code == 0) {
        this.productList = data.rows;
        this.total = data.total;
      }
    },
    async getBannerList() {
      let { code, data } = await BannerList_api();
      if (code == 0) {
        this.bannerList = data;
        console.log(this.bannerList);
      }
    },
    changePageNumber(val) {
      console.log(val);
      this.searchData.pageNumber = val;

      this.getProductList();
    },
    toCommoditydetails(val) {
      console.log("去详情页", val);
      this.$router.push(
        {
          path: "/commoditydetails",
          query: {
            commodityId: val
          }
        },
        () => { }
      );
      // this.$router.push(
      //   {
      //     name: "Commoditydetails",
      //     query: {
      //       commodityId: val
      //     }
      //   },
      //   () => {}
      // );
    },

    async getCategoryList() {
      let { code, data } = await categoryList_api();
      if (code == 0) {
        this.categoryList = data;
      }
    },
    changeCategory(item) {
      if (item && item.id) {
        this.categoryId = item.id;
        this.$bus.$emit("searchCategoryInfo", item);
        this.searchData.productCateId = item.id;
        this.searchData.key = "";
      } else {
        this.$bus.$emit("searchCategoryInfo", "");
        this.searchData.productCateId = "";
        this.searchData.key = "";
        this.categoryId = "";
      }
      this.searchFn();
    },
    chooseSwipe(item) {
      console.log(item);
      this.showBaner = false;
      if (item.type == 3) {
        this.$router.push(
          {
            path: "/commoditydetails",
            query: {
              commodityId: item.productId
            }
          },
          () => { }
        );
      }
    }
  }
};
</script>

<style lang="less" scoped>
// .categoryitembox:last-child{
//     border-right: 1px solid #dddddd;
// }
.my-swipe {
  width: 750px;
  height: calc(100vh - 102px);
}

.swipeimgbox {
  width: 750px;
  height: calc(100vh - 102px);
  display: flex;
  justify-content: center;
  align-items: center;
}

.swipeimg {
  width: 300px;
  height: 300px;
}

.mainheadbox {
  margin-top: 58px;
  text-align: center;
  font-size: 24px;
  font-family: PingFang;
  font-weight: 400;
  color: #333333;
}

.mainbox {
  margin: 0 auto;
  margin-top: 30px;
}

.mainitembox {
  margin-left: 30px;
  display: inline-block;
  vertical-align: top;
  width: 330px;
  text-align: center;
  margin-bottom: 40px;
}

.mainimg {
  width: 330px;
}

.main-name-box {
  margin-top: 20px;
  font-size: 22px;
  font-family: PingFang;
  font-weight: 400;
  color: #1e1e1e;
}

.main-price-box {
  margin-top: 10px;
  font-size: 22px;
  font-family: PingFang;
  font-weight: 400;
  color: #000000;
}

.main-tag-box {
  display: inline-block;
  vertical-align: top;
  margin: 18px 10px;
  width: fit-content;
  padding: 0 10px;
  height: 25px;
  line-height: 25px;
  text-align: center;

  font-size: 20px;
  font-family: PingFang;
  font-weight: 500;
  color: #7c7d7d;

  border: 1px solid #bbbbbb;
}

.num0 {
  color: #e63c3d;

  border: 1px solid #e63c3d;
}

.main-foot-box {
  margin: 0 auto;
  margin-top: 70px;
  width: 400px;
  height: 62px;
  border: 2px solid #d2d2d2;
  line-height: 62px;
  text-align: center;

  font-size: 22px;
  font-family: PingFang;
  font-weight: 500;
  color: #7c7d7d;
}

.margintop10 {
  margin-top: 10px;
  padding-bottom: 50px;
  text-align: center;
}

.zhanwei {
  width: 100%;
  height: 50px;
}

.logobox {
  width: 130px;
  height: 48px;
  margin: 0 auto;
}

.logoimg {
  width: 130px;
  height: 48px;
}

.categorybox {
  display: flex;
  flex-wrap: wrap;
  // border-top: 1px solid #dddddd;
  margin-top: 30px;
}

.categoryitembox {
  width: 148px;
  height: 60px;
  // border-top: 1px solid #dddddd;

  // border-right: 1px solid #dddddd;
  // border-bottom: 1px solid #dddddd;

  font-size: 24px;
  font-family: PingFang SC;
  font-weight: 500;
  color: #333333;
  line-height: 60px;
  text-align: center;
}

.categoryitembox:nth-child(5n) {
  border-right: none;
}

.checkitembox {
  background: #986565;
}

// .van-pagination__next .van-hairline
/deep/.van-pagination__item {
  color: #a3a397 !important;
  background-color: #e7e7df;
}

/deep/ .el-pagination .btn-next,
/deep/ .el-pagination .btn-prev {
  background: none;
}

/deep/.el-pager li {
  background: none;
  font-weight: 400;
}

/deep/.el-pagination button:disabled {
  background: none;
}

/deep/.el-pager li.active {
  // color: #986565;
  color: #303133;
  font-weight: 700;
}

/deep/.el-pagination button:hover {
  color: #303133;
}

/deep/.el-pager li:hover {
  color: #303133;

}

@media (min-width: 1000px) {
  .swipeimg {
    width: 300px;
    height: 300px;
  }

  .my-swipe {
    width: 100%;
    height: 100%;

  }

  .zhanwei {
    width: 100%;
    height: 0;
  }
}

.zrlcategorybox {
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
  // border-top: 1px solid #dddddd;
  margin-top: 30px;
}

.zrlcategoryitembox {
  padding: 2px 2px 2px 5px;
  color: #2e2e2e;
  display: inline-block;
  font-size: 11px;
  line-height: 14px;
  margin-right: 20px;
  cursor: pointer;
}

.zrlcheckitembox {
  color: #c06c84;
}

.zrl {
  display: none,

}

.zrlmainbox {
  max-width: 1200px;
  display: none;
  flex-wrap: wrap;
  margin: 0 auto;
  margin-top: 30px;

  .zrlmainitembox {
    flex: 0 1 calc(33% - 10px);
    margin-bottom: 50px;
    margin-right: 20px;
    position: relative;

    .zrlmainimg {
      width: 100%;
    }

    .zrlmain-name-box {
      font-size: 11px;
      color: #555555;
      margin-top: 10px;
      margin-left: 5px;
      text-align: center;
    }

    .zrlmain-price-box {
      font-size: 11px;
      color: #555555;
      margin-top: 5px;
      margin-left: 5px;
      text-align: center;
    }

    .zrlmain-tag-box {
      width: 100%;
      position: absolute;
      bottom: 0;
      background-color: rgba(191, 109, 130, .95);
      font-size: 11px;
      line-height: 11px;
      padding: 9px 0 6px;
      font-weight: normal;
      line-height: 13px;
      color: #fff;
      text-align: center;
      background-color: rgba(191, 109, 130, .95);
    }
  }

  .zrlmainitembox:nth-child(3n) {
    margin-right: 0;
  }
}




@media screen and (min-width: 1000px) {
  .categorybox {
    display: none;
  }

  .mainheadbox {
    display: none;
  }

  .mainbox {
    display: none;
  }

  .zrl {
    display: flex,

  }

  .zrlmainbox {
    display: flex;
  }



}
</style>
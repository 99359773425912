<template>
  <div>
    <!-- <Logodia></Logodia> -->
    <div class="lg">
      <div class="mainbox">
        <div class="flexbox marginbottom60">
          <div class="titlelable">退货信息</div>
          <div class="titleval">共{{ applySaledOrderInfo[0].num }}件</div>
        </div>

        <div class="mainitembox" v-for="item in applySaledOrderInfo" :key="item.id">
          <div>
            <img class="mainimg" :src="item.productMainImage" alt="" />
          </div>
          <div class="mainiteninfobox">
            <div class="flexboxtwo">
              <div class="infonamebox">{{ item.productName }}</div>
              <div class="infotitlebox"></div>
              <div class="skuinfo" v-if="item.productSkuName">
                <div class="skuinfoitem" v-for="(ctem, index) in item.productSkuName" :key="index">
                  {{ ctem }}
                </div>
              </div>
              <div class="flexbox">
                <div class="skuinfoprice">￥{{ item.unitPrice }}</div>
                <div class="skunum">x{{ item.num }}</div>
              </div>
            </div>
          </div>
        </div>
        <div class="margintop41">
          <van-divider :style="{ color: '#c4c4c4' }"></van-divider>
        </div>
      </div>
      <div class="mainbox pad50">
        <div class="maintitle">退款信息</div>
        <div class="flexbox" @click="openAfterSaleType">
          <div>退款类型*</div>
          <div>{{ afterSaleTypeLable }}<van-icon name="arrow" /></div>
        </div>

        <div class="flexbox">
          <div>退款金额*</div>
          <div>￥ {{ applySaledOrderInfo[0].rtAmount || 0 }}</div>
        </div>
        <div class="pricelable">不可修改</div>
      </div>
      <div class="mainbox">
        <div class="maintitle">补充描述和凭证</div>
        <div>
          <van-field v-model="addOrderAfterSaleInfo.description" rows="3" autosize type="textarea"
            placeholder="补充描述，有利于更好的处理售后问题" />
          <van-uploader multiple :after-read="afterRead" v-model="fileList" @delete="onDelete" />
        </div>
      </div>
      <div class="zhanweifoot"></div>
      <div class="footbox">
        <div class="footbtnbox" @click="addOrderAfterSale">提交</div>
      </div>
      <van-action-sheet v-model="showoAfterSaleTypeSheet" :actions="afterSaleTypeList" cancel-text="取消"
        close-on-click-action @cancel="onCancel" @select="onConfirm" />
    </div>
    <div class="zrl zrlbox">
      <div class="zrlmainbox">
        <div class="zrlflexbox zrlmarginbottom60">
          <div class="zrltitlelable">退货信息</div>
          <div class="zrltitleval">共{{ applySaledOrderInfo[0].num }}件</div>
        </div>

        <div class="zrlmainitembox" v-for="item in applySaledOrderInfo" :key="item.id">
          <div>
            <img class="zrlmainimg" :src="item.productMainImage" alt="" />
          </div>
          <div class="zrlmainiteninfobox">
            <div class="zrlflexboxtwo">
              <div class="zrlinfonamebox">{{ item.productName }}</div>
              <div class="zrlinfotitlebox"></div>
              <div class="zrlskuinfo" v-if="item.productSkuName">
                <div class="zrlskuinfoitem" v-for="(ctem, index) in item.productSkuName" :key="index">
                  {{ ctem }}
                </div>
              </div>
              <div class="zrlflexbox">
                <div class="zrlskuinfoprice">￥{{ item.unitPrice }}</div>
                <div class="zrlskunum">x{{ item.num }}</div>
              </div>
            </div>
          </div>
        </div>
        <div class="zrlmargintop41">
          <van-divider :style="{ color: '#c4c4c4' }"></van-divider>
        </div>
      </div>
      <div class="zrlmainbox zrlpad50">
        <div class="zrlmaintitle">退款信息</div>
        <div class="zrlflexbox" @click="openAfterSaleType">
          <div>退款类型*</div>
          <div>{{ afterSaleTypeLable }}<van-icon name="arrow" /></div>
        </div>

        <div class="zrlflexbox">
          <div>退款金额*</div>
          <div>￥ {{ applySaledOrderInfo[0].rtAmount || 0 }}</div>
        </div>
        <div class="zrlpricelable">不可修改</div>
      </div>
      <div class="zrlmainbox">
        <div class="zrlmaintitle">补充描述和凭证</div>
        <div>
          <van-field v-model="addOrderAfterSaleInfo.description" rows="3" autosize type="textarea"
            placeholder="补充描述，有利于更好的处理售后问题" />
          <van-uploader multiple :after-read="afterRead" v-model="fileList" @delete="onDelete" />
        </div>
      </div>
      <div class="zrlzhanweifoot"></div>
      <div class="zrlfootbox">
        <div class="zrlfootbtnbox" @click="addOrderAfterSale">提交</div>
      </div>
      <van-action-sheet v-model="showoAfterSaleTypeSheet" :actions="afterSaleTypeList" cancel-text="取消"
        close-on-click-action @cancel="onCancel" @select="onConfirm" />
    </div>
  </div>
</template>

<script>
import {
  saleProduct_api,
  orderInfo_api,
  uploadList_api,
  addOrderAfterSale_api,
} from "../../apis/order";
import local from "@/utils/local";
import axios from "axios";
import Logodia from "../../components/Logodia.vue";
export default {
  components: {
    Logodia,
  },
  data() {
    return {
      orderId: "",
      applySaledOrderInfo: [{ num: 0 }],
      orderInfo: "",
      message: "",
      fileList: [],
      uploadList: [],
      afterSaleTypeLable: "退货退款",
      showoAfterSaleTypeSheet: false,
      afterSaleTypeList: [
        { name: "仅退款", value: "1" },
        { name: "退货退款", value: "2" },
      ],
      addOrderAfterSaleInfo: {
        afterSaleType: "2",
        amount: 0,
        description: "",
        imgUrl: "",
        orderId: "",
        orderProductIds: "",
      },
    };
  },
  created() {
    this.orderId = this.$route.query.id;
    this.getSaleProduct();
  },
  methods: {
    async getSaleProduct() {
      let { code, data } = await saleProduct_api({
        id: this.orderId,
      });
      if (code == 0) {
        this.applySaledOrderInfo = data;
        if (this.applySaledOrderInfo[0].orderStatus == 1) {
          this.afterSaleTypeLable = "仅退款";
          this.addOrderAfterSaleInfo.afterSaleType = 1;
        }

        this.addOrderAfterSaleInfo.amount =
          this.applySaledOrderInfo[0].rtAmount;
        this.addOrderAfterSaleInfo.orderId =
          this.applySaledOrderInfo[0].orderId;
        this.addOrderAfterSaleInfo.orderProductIds =
          this.applySaledOrderInfo[0].id;

        // this.showTuihuoPop = true;
      }
    },

    async afterRead(file) {
      console.log(file);
      // let newFileList = [];
      // file.forEach((item) => {
      //   newFileList.push(item.content);
      // });

      if (file.constructor != Array) {
        let formData = new FormData();
        // console.log('file.constructor != Array')
        formData.append("file", file.file);
        this.uploadFile(formData);
      } else {
        //  console.log('file.constructor == Array')
        file.forEach((item) => {
          let formData = new FormData();
          formData.append("file", item.file);
          this.uploadFile(formData);
        });
      }
    },
    uploadFile(formData) {
      axios
        .post(
          "https://www.seooocookie.cn/public/otaHotel/common/uploadList",
          formData,
          {
            headers: {
              "Content-Type": "multipart/form-data",
            },
          }
        )
        .then((res) => {
          this.uploadList.push(res.data.data.url);
          console.log(this.uploadList);
        })
        .catch((err) => {
          console.log(err);
        });
    },
    onDelete(file, detail) {
      this.uploadList.splice(detail.index, 1);
      console.log(this.uploadList);
    },
    openAfterSaleType() {
      if (this.applySaledOrderInfo[0].orderStatus != 1) {
        this.showoAfterSaleTypeSheet = true;
      }
    },
    onConfirm(val) {
      this.afterSaleTypeLable = val.name;
      this.addOrderAfterSaleInfo.afterSaleType = val.value;
    },
    onCancel() {
      this.showoAfterSaleTypeSheet = false;
    },
    async addOrderAfterSale() {
      if (this.uploadList.length > 0) {
        this.addOrderAfterSaleInfo.imgUrl = this.uploadList.join(",");
      }
      let { code, data } = await addOrderAfterSale_api(
        this.addOrderAfterSaleInfo
      );
      if (code == 0) {
        this.$toast.success("申请成功，等待商家核对~");
        this.$router.push(
          {
            path: "/orderlist",
            query: {

              type: '',
            },
          },
          () => { }
        );
      }
    },
  },
};
</script>

<style lang="less" scoped>
.mainbox {
  margin: 0 auto;
  width: 630px;
  padding: 40px 30px;
  margin-bottom: 20px;
  background: #fff;
}

.marginbottom60 {
  margin-bottom: 60px;
}

.flexbox {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.titlelable {
  font-size: 24px;
  font-family: PingFang;
  font-weight: bold;
  color: #000000;
}

.titleval {
  font-size: 24px;
  font-family: PingFang;
  font-weight: bold;
  color: #999999;
  letter-spacing: 5px;
}

.mainitembox {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 20px;
}

.mainimg {
  width: 180px;
  height: 180px;
  border-radius: 10px;
  object-fit: cover;
}

.mainiteninfobox {
  width: 420px;
  height: 180px;
}

.flexboxtwo {
  height: 180px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.infonamebox {
  font-size: 24px;
  font-family: PingFang;
  font-weight: bold;
  color: #666666;
}

.infotitlebox {
  font-size: 24px;
  font-family: PingFang;
  font-weight: 500;
  color: #000000;
}

.skuinfo {
  font-size: 24px;
  font-family: PingFang;
  font-weight: 500;
  color: #999999;
}

.skuinfoitem {
  display: inline-block;
  vertical-align: top;
  margin-right: 10px;
}

.skuinfoprice {
  font-size: 30px;
  font-family: PingFang;
  font-weight: bold;
  color: #000000;
}

.skunum {
  font-size: 24px;
  font-family: PingFang;
  font-weight: bold;
  color: #000000;
  letter-spacing: 3px;
}

.margintop41 {
  margin-top: 40px;
  margin-bottom: 30px;
}

.pad50 {
  // padding-top: 50px;
  // padding-bottom: 50px;

  font-size: 24px;
  font-family: PingFang;
  font-weight: bold;
  color: #000000;
  line-height: 60px;
}

.pricelable {
  font-size: 20px;
  font-family: PingFang;
  font-weight: bold;
  color: #999999;
}

.maintitle {
  font-size: 30px;
  font-family: PingFang;
  font-weight: bold;
  color: #010101;
  margin-bottom: 30px;
}

.zhanweifoot {
  width: 100%;
  height: 175px;
}

.footbox {
  width: 750px;
  height: 150px;
  background: #ffffff;
  position: fixed;
  left: 0px;
  bottom: 0px;
}

.footbtnbox {
  margin: 0 auto;
  margin-top: 30px;
  width: 690px;
  height: 80px;
  background: #986665;
  line-height: 80px;
  text-align: center;
  font-size: 24px;
  font-family: PingFang;
  font-weight: bold;
  color: #ffffff;
}
//pc
.zrlmainbox {
  margin: 0 auto;
  width: 630px;
  padding: 40px 30px;
  margin-bottom: 20px;
  // background: #fff;
}

.zrlmarginbottom60 {
  margin-bottom: 60px;
}

.zrlflexbox {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.zrltitlelable {
  font-size: 24px;
  font-family: PingFang;
  font-weight: bold;
  color: #000000;
}

.zrltitleval {
  font-size: 24px;
  font-family: PingFang;
  font-weight: bold;
  color: #999999;
  letter-spacing: 5px;
}

.zrlmainitembox {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 20px;
}

.zrlmainimg {
  width: 180px;
  height: 180px;
  border-radius: 10px;
  object-fit: cover;
}

.zrlmainiteninfobox {
  width: 420px;
  height: 180px;
}

.zrlflexboxtwo {
  height: 180px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.zrlinfonamebox {
  font-size: 24px;
  font-family: PingFang;
  font-weight: bold;
  color: #666666;
}

.zrlinfotitlebox {
  font-size: 24px;
  font-family: PingFang;
  font-weight: 500;
  color: #000000;
}

.zrlskuinfo {
  font-size: 24px;
  font-family: PingFang;
  font-weight: 500;
  color: #999999;
}

.zrlskuinfoitem {
  display: inline-block;
  vertical-align: top;
  margin-right: 10px;
}

.zrlskuinfoprice {
  font-size: 30px;
  font-family: PingFang;
  font-weight: bold;
  color: #000000;
}

.zrlskunum {
  font-size: 24px;
  font-family: PingFang;
  font-weight: bold;
  color: #000000;
  letter-spacing: 3px;
}

.zrlmargintop41 {
  margin-top: 40px;
  margin-bottom: 30px;
}

.zrlpad50 {
  // padding-top: 50px;
  // padding-bottom: 50px;

  font-size: 24px;
  font-family: PingFang;
  font-weight: bold;
  color: #000000;
  line-height: 60px;
}

.zrlpricelable {
  font-size: 20px;
  font-family: PingFang;
  font-weight: bold;
  color: #999999;
}

.zrlmaintitle {
  font-size: 30px;
  font-family: PingFang;
  font-weight: bold;
  color: #010101;
  margin-bottom: 30px;
}

.zrlzhanweifoot {
  width: 100%;
  height: 175px;
}

.zrlfootbox {
  width: 750px;
  height: 150px;
  // background: #ffffff;
  position: fixed;
  left: 50%;
  transform: translateX(-50%);
  bottom: 0px;
}

.zrlfootbtnbox {
  margin: 0 auto;
  margin-top: 30px;
  width: 690px;
  height: 80px;
  background: #986665;
  line-height: 80px;
  text-align: center;
  font-size: 24px;
  font-family: PingFang;
  font-weight: bold;
  color: #ffffff;
}

.zrlbox{
    display: none;
  }
@media screen and (min-width: 1000px) {
  .lg{
    display: none;
  }
  .zrlbox{
    display: block;
  }
}
</style>
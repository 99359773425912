<template>
  <div>
    <div class="lg">
    <!-- <Logodia></Logodia> -->
    <!-- <div class="zhanweiforconpon"></div> -->
    <div class="couponheadbox">
      <div
        class="inlineblock"
        @click="changeTypeZero"
        :class="[searchData.isUse == 0 ? 'checkedbox' : 'detailbox']"
      >
        未使用
      </div>
      <div
        class="inlineblock"
        @click="changeTypeOne"
        :class="[searchData.isUse == 1 ? 'checkedbox' : 'detailbox']"
      >
        已使用/已失效
      </div>
    </div>
    <div class="content">
      <div v-if="searchData.isUse == 0">
        <div class="mainitembox" v-for="item in couponList" :key="item.id">
          <div class="canuseleftbox">
            <div class="padtop50" v-if="item.type == 'CASH'">
              <span class="canlefticon">￥</span>
              <span class="canleftnum">{{ item.benefitCash }}</span>
            </div>
            <div class="padtop50" v-if="item.type == 'DISCOUNT'">
              <span class="canleftnumt"
                >{{ item.benefitDiscount * 10 }}折券</span
              >
            </div>
            <div class="canleftlable" v-if="item.conditionAmount > 0">
              满{{ item.conditionAmount }}元使用
            </div>
            <div class="canleftlable" v-else>无门槛优惠券</div>
          </div>
          <div class="canrightbox">
            <div class="canrightinfo">
              <div class="columnflexbox">
                <div class="canrightvale">
                  <span v-if="item.type == 'CASH'">【现金券】</span>
                  <span v-if="item.type == 'DISCOUNT'">【折扣券】</span
                  >{{ item.name | formartName }}
                </div>
                <div class="canrightlable">
                  {{ item.memo }} 有效期 {{ item.startDate }}至{{
                    item.endDate
                  }}
                </div>
              </div>
            </div>
            <div class="canrightbtn" @click="toIndex">立即使用</div>
          </div>
        </div>
      </div>
      <div v-if="searchData.isUse == 1">
        <div class="mainitembox" v-for="item in couponList" :key="item.id">
          <div class="canuserightbox">
            <div class="padtop50" v-if="item.type == 'CASH'">
              <span class="canrighticon">￥</span>
              <span class="canleftnumtwo">{{ item.benefitCash }}</span>
            </div>
            <!-- <div class="padtop50" v-if="item.type == 'CASH'">
              <span class="canrighticon">￥</span>
              <span class="canleftnumtwo">{{ item.benefitCash }}</span>
            </div> -->
            <div class="padtop50" v-if="item.type == 'DISCOUNT'">
              <span class="canleftnumtwot"
                >{{ item.benefitDiscount * 10 }}折券</span
              >
            </div>
            <div class="uncanleftlable" v-if="item.conditionAmount > 0">
              满{{ item.conditionAmount }}元使用
            </div>
            <div class="uncanleftlable" v-else>无门槛优惠券</div>
          </div>
          <div class="canrightbox">
            <div class="canrightinfo">
              <div class="columnflexbox">
                <div class="uncanrightvale">
                <span v-if="item.type == 'CASH'">【现金券】</span>
                <span v-if="item.type == 'DISCOUNT'">【折扣券】</span
                >{{ item.name | formartName }}
              </div>
              <div class="canrightlable">
                {{ item.memo }} 有效期 {{ item.startDate }}至{{ item.endDate }}
              </div>

              </div>
             
            </div>
            <div class="uncanrightbtn">
              <img
                v-if="item.isUse == 1"
                class="uncanrightimg"
                src="../../assets/imgs/yishiyong@3x.png"
                alt=""
              />
              <img
                v-if="item.isUse == 2"
                class="uncanrightimg"
                src="../../assets/imgs/yishixiao@3x.png"
                alt=""
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
  <div class="zrl zrlcontentbox">
    <!-- <Logodia></Logodia> -->
    <!-- <div class="zhanweiforconpon"></div> -->
    <div class="zrlcouponheadbox">
      <div
        class="zrlinlineblock"
        @click="changeTypeZero"
        :class="[searchData.isUse == 0 ? 'zrlcheckedbox' : 'zrldetailbox']"
      >
        未使用
      </div>
      <div
        class="zrlinlineblock"
        @click="changeTypeOne"
        :class="[searchData.isUse == 1 ? 'zrlcheckedbox' : 'zrldetailbox']"
      >
        已使用/已失效
      </div>
    </div>
    <div class="zrlcontent">
      <div v-if="searchData.isUse == 0">
        <div class="zrlmainitembox" v-for="item in couponList" :key="item.id">
          <div class="zrlcanuseleftbox">
            <div class="zrlpadtop50" v-if="item.type == 'CASH'">
              <span class="zrlcanlefticon">￥</span>
              <span class="zrlcanleftnum">{{ item.benefitCash }}</span>
            </div>
            <div class="zrlpadtop50" v-if="item.type == 'DISCOUNT'">
              <span class="zrlcanleftnumt"
                >{{ item.benefitDiscount * 10 }}折券</span
              >
            </div>
            <div class="zrlcanleftlable" v-if="item.conditionAmount > 0">
              满{{ item.conditionAmount }}元使用
            </div>
            <div class="zrlcanleftlable" v-else>无门槛优惠券</div>
          </div>
          <div class="zrlcanrightbox">
            <div class="zrlcanrightinfo">
              <div class="zrlcolumnflexbox">
                <div class="zrlcanrightvale">
                  <span v-if="item.type == 'CASH'">【现金券】</span>
                  <span v-if="item.type == 'DISCOUNT'">【折扣券】</span
                  >{{ item.name | formartName }}
                </div>
                <div class="zrlcanrightlable">
                  {{ item.memo }} 有效期 {{ item.startDate }}至{{
                    item.endDate
                  }}
                </div>
              </div>
            </div>
            <div class="zrlcanrightbtn" @click="toIndex">立即使用</div>
          </div>
        </div>
      </div>
      <div v-if="searchData.isUse == 1">
        <div class="zrlmainitembox" v-for="item in couponList" :key="item.id">
          <div class="zrlcanuserightbox">
            <div class="zrlpadtop50" v-if="item.type == 'CASH'">
              <span class="zrlcanrighticon">￥</span>
              <span class="zrlcanleftnumtwo">{{ item.benefitCash }}</span>
            </div>
            <!-- <div class="padtop50" v-if="item.type == 'CASH'">
              <span class="canrighticon">￥</span>
              <span class="canleftnumtwo">{{ item.benefitCash }}</span>
            </div> -->
            <div class="zrlpadtop50" v-if="item.type == 'DISCOUNT'">
              <span class="zrlcanleftnumtwot"
                >{{ item.benefitDiscount * 10 }}折券</span
              >
            </div>
            <div class="zrluncanleftlable" v-if="item.conditionAmount > 0">
              满{{ item.conditionAmount }}元使用
            </div>
            <div class="zrluncanleftlable" v-else>无门槛优惠券</div>
          </div>
          <div class="zrlcanrightbox">
            <div class="zrlcanrightinfo">
              <div class="zrlcolumnflexbox">
                <div class="zrluncanrightvale">
                <span v-if="item.type == 'CASH'">【现金券】</span>
                <span v-if="item.type == 'DISCOUNT'">【折扣券】</span
                >{{ item.name | formartName }}
              </div>
              <div class="zrlcanrightlable">
                {{ item.memo }} 有效期 {{ item.startDate }}至{{ item.endDate }}
              </div>

              </div>
             
            </div>
            <div class="zrluncanrightbtn">
              <img
                v-if="item.isUse == 1"
                class="zrluncanrightimg"
                src="../../assets/imgs/yishiyong@3x.png"
                alt=""
              />
              <img
                v-if="item.isUse == 2"
                class="zrluncanrightimg"
                src="../../assets/imgs/yishixiao@3x.png"
                alt=""
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
  </div>
</template>

<script>
import { couponList_api } from "../../apis/mine";
import Logodia from "../../components/Logodia.vue";
export default {
  components: {
    Logodia,
  },
  data() {
    return {
      searchData: {
        isUse: 0,
        pageNumber: 1,
        pageSize: 999,
      },
      couponList: [],
    };
  },
  created() {
    this.getCouponList();
  },
  filters: {
    formartName(val) {
      if (val.length > 17) {
        let newVal = val.substring(0, 17);
        return newVal;
      } else {
        return val;
      }
    },
  },

  methods: {
    changeTypeZero() {
      if (this.searchData.isUse == 1) {
        this.searchData.isUse = 0;
        this.getCouponList();
      }
    },
    changeTypeOne() {
      if (this.searchData.isUse == 0) {
        this.searchData.isUse = 1;
        this.getCouponList();
      }
    },
    async getCouponList() {
      let { code, data } = await couponList_api(this.searchData);
      if (code == 0) {
        this.couponList = data.rows;
      }
    },
    toIndex() {
      this.$router.push(
        {
          path: "/index",
        },
        () => {}
      );
    },
  },
};
</script>

<style lang="less" scoped>
.zhanweiforconpon {
  width: 100%;
  height: 70px;
}
.couponheadbox {
  margin: 0 auto;
  width: 690px;
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.inlineblock {
  //   display: inline-block;
  //   vertical-align: top;
}
.checkedbox {
  width: 50%;
  height: 60px;
  background: #986665;
  text-align: center;
  line-height: 60px;

  font-size: 24px;
  font-family: PingFang;
  font-weight: 500;
  color: #ffffff;
}
.detailbox {
  width: calc(50% - 2px);
  height: 60px;
  background: #ffffff;
  border: 1px solid #dddddd;
  line-height: 60px;
  text-align: center;

  font-size: 24px;
  font-family: PingFang;
  font-weight: 500;
  color: #333333;
}
.content {
  width: 690px;
  margin: 0 auto;
}
.mainitembox {
  margin-top: 20px;
  border-radius: 15px;
  background: #fff;
  //   display: flex;
  //   justify-content: space-between;
  //   align-items: center;
}
.canuseleftbox {
  display: inline-block;
  vertical-align: top;
  width: 188px;
  height: 188px;
  background: #986665;
  border-radius: 15px 0px 0px 15px;
}
.padtop50 {
  padding-top: 30px;
  text-align: center;
}
.canlefticon {
  // margin-left: 34px;
  font-size: 30px;
  font-family: PingFang;
  font-weight: 500;
  color: #ffffff;
}
.canleftnum {
  font-size: 60px;
  font-family: PingFang;
  font-weight: bold;
  color: #ffffff;
}
.canleftnumt {
  font-size: 48px;
  font-family: PingFang;
  font-weight: bold;
  color: #ffffff;
}
.canleftlable {
  text-align: center;
  font-size: 24px;
  font-family: PingFang;
  font-weight: 500;
  color: #ffffff;
}
.canrightbox {
  width: 502px;
  height: 188px;
  background: #ffffff;
  border-radius: 0px 15px 15px 0px;
  display: inline-block;
  vertical-align: top;
}
.canrightinfo {
  margin-left: 30px;
  width: 350px;
  height: 188px;
  // background: #4d36ff;
  display: inline-block;
  vertical-align: top;
}
.columnflexbox {
  display: flex;
  justify-content: space-around;
  flex-direction: column;
  height: 188px;
}
.canrightvale {
  font-size: 30px;
  font-family: PingFang;
  font-weight: 500;
  color: #333333;
 // padding-top: 30px;
  // margin-bottom: 20px;
}
.canrightlable {
  font-size: 20px;
  font-family: PingFang;
  font-weight: 500;
  color: #999999;
}
.canrightbtn {
  width: 112px;
  height: 44px;
  background: #986665;
  border-radius: 22px;
  display: inline-block;

  line-height: 44px;
  text-align: center;
  font-size: 20px;
  font-family: PingFang;
  font-weight: 500;
  color: #ffffff;
  position: relative;
  top: 72px;
}
.canuserightbox {
  display: inline-block;
  vertical-align: top;
  width: 188px;
  height: 188px;
  background: #e4e4e4;
  border-radius: 15px 0px 0px 15px;
}
.canrighticon {
  margin-left: 34px;
  font-size: 30px;
  font-family: PingFang;
  font-weight: 500;
  color: #c9c9c9;
}
.canleftnumtwo {
  font-size: 60px;
  font-family: PingFang;
  font-weight: bold;
  color: #c9c9c9;
}
.canleftnumtwot {
  font-size: 48px;
  font-family: PingFang;
  font-weight: bold;
  color: #c9c9c9;
}
.uncanleftlable {
  text-align: center;
  font-size: 24px;
  font-family: PingFang;
  font-weight: 500;
  color: #c9c9c9;
}
.uncanrightvale {
  font-size: 30px;
  font-family: PingFang;
  font-weight: 500;
  color: #999999;
  padding-top: 30px;
  margin-bottom: 20px;
}
.uncanrightbtn {
  display: inline-block;
  position: relative;
  top: 35px;
}
.uncanrightimg {
  width: 118px;
  height: 118px;
}
//pc
.zrlzhanweiforconpon {
  width: 100%;
  height: 70px;
}
.zrlcouponheadbox {
  margin: 0 auto;
  width: 690px;
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.zrlinlineblock {
  //   display: inline-block;
  //   vertical-align: top;
}
.zrlcheckedbox {
  width: 50%;
  height: 60px;
  background: #986665;
  text-align: center;
  line-height: 60px;

  font-size: 24px;
  font-family: PingFang;
  font-weight: 500;
  color: #ffffff;
}
.zrldetailbox {
  width: calc(50% - 2px);
  height: 60px;
  background: #ffffff;
  border: 1px solid #dddddd;
  line-height: 60px;
  text-align: center;

  font-size: 24px;
  font-family: PingFang;
  font-weight: 500;
  color: #333333;
}
.zrlcontent {
  width: 690px;
  margin: 0 auto;
}
.zrlmainitembox {
  margin-top: 20px;
  border-radius: 15px;
  background: #fff;
  //   display: flex;
  //   justify-content: space-between;
  //   align-items: center;
}
.zrlcanuseleftbox {
  display: inline-block;
  vertical-align: top;
  width: 188px;
  height: 188px;
  background: #986665;
  border-radius: 15px 0px 0px 15px;
}
.zrlpadtop50 {
  padding-top: 30px;
  text-align: center;
}
.zrlcanlefticon {
  // margin-left: 34px;
  font-size: 30px;
  font-family: PingFang;
  font-weight: 500;
  color: #ffffff;
}
.zrlcanleftnum {
  font-size: 60px;
  font-family: PingFang;
  font-weight: bold;
  color: #ffffff;
}
.zrlcanleftnumt {
  font-size: 48px;
  font-family: PingFang;
  font-weight: bold;
  color: #ffffff;
}
.zrlcanleftlable {
  text-align: center;
  font-size: 24px;
  font-family: PingFang;
  font-weight: 500;
  color: #ffffff;
}
.zrlcanrightbox {
  width: 502px;
  height: 188px;
  background: #ffffff;
  border-radius: 0px 15px 15px 0px;
  display: inline-block;
  vertical-align: top;
}
.zrlcanrightinfo {
  margin-left: 30px;
  width: 350px;
  height: 188px;
  // background: #4d36ff;
  display: inline-block;
  vertical-align: top;
}
.zrlcolumnflexbox {
  display: flex;
  justify-content: space-around;
  flex-direction: column;
  height: 188px;
}
.zrlcanrightvale {
  font-size: 30px;
  font-family: PingFang;
  font-weight: 500;
  color: #333333;
 // padding-top: 30px;
  // margin-bottom: 20px;
}
.zrlcanrightlable {
  font-size: 20px;
  font-family: PingFang;
  font-weight: 500;
  color: #999999;
}
.zrlcanrightbtn {
  width: 112px;
  height: 44px;
  background: #986665;
  border-radius: 22px;
  display: inline-block;

  line-height: 44px;
  text-align: center;
  font-size: 20px;
  font-family: PingFang;
  font-weight: 500;
  color: #ffffff;
  position: relative;
  top: 72px;
}
.zrlcanuserightbox {
  display: inline-block;
  vertical-align: top;
  width: 188px;
  height: 188px;
  background: #e4e4e4;
  border-radius: 15px 0px 0px 15px;
}
.zrlcanrighticon {
  margin-left: 34px;
  font-size: 30px;
  font-family: PingFang;
  font-weight: 500;
  color: #c9c9c9;
}
.zrlcanleftnumtwo {
  font-size: 60px;
  font-family: PingFang;
  font-weight: bold;
  color: #c9c9c9;
}
.zrlcanleftnumtwot {
  font-size: 48px;
  font-family: PingFang;
  font-weight: bold;
  color: #c9c9c9;
}
.zrluncanleftlable {
  text-align: center;
  font-size: 24px;
  font-family: PingFang;
  font-weight: 500;
  color: #c9c9c9;
}
.zrluncanrightvale {
  font-size: 30px;
  font-family: PingFang;
  font-weight: 500;
  color: #999999;
  padding-top: 30px;
  margin-bottom: 20px;
}
.zrluncanrightbtn {
  display: inline-block;
  position: relative;
  top: 35px;
}
.zrluncanrightimg {
  width: 118px;
  height: 118px;
}
.zrlcontentbox{
  display: none;
  width: 690px;
  margin: 0 auto;
}
@media screen and (min-width: 1000px) {
  .lg{
    display: none;
  }
  .zrlcontentbox{
    display: block;
  }
}
</style>
<template>
  <div>
    <div class="lg fontsize">
      <div class="fontsize-title">温馨提示</div>


      <br />请您务必仔细阅读本网站的《购买须知》和《售后服务》之后再确认购买。
      <br /> <br />
      <div style="font-weight: 600;">
        本网站不支持退货和换款，请谨慎购买。 <br />
        本网站支持更换同款尺码或质量原因需要更换同款产品。

      </div>
      <br />我们竭诚为您提供优质的服务，呈现独特的美衣，但美衣的呈现也绝不是偶然，需要历经若干工序的精打细磨，每一道工序都凝聚无数人的努力付出。
      同时，由于服装产品的属性和工艺限制，产品会存在一些允差、漏剪掉的线头、个别产品因为面料生产过程中交接处的线结等等，我们努力将其控制在国家行业标准范围内。如果介意，请慎拍。

      <br />
      <br /> <br /> <br /> <br />
      <div class="lastbox">
        感谢您对seooocookie的支持和厚爱！ <br />
        祝您生活愉快！
      </div>
    </div>
    <div class="zrl zrlcontent zrlfontsize">
      <div class="zrlfontsize-title">温馨提示</div>


      <br />请您务必仔细阅读本网站的《购买须知》和《售后服务》之后再确认购买。
      <br /> <br />
      <div style="font-weight: 600;">
        本网站不支持退货和换款，请谨慎购买。 <br />
        本网站支持更换同款尺码或质量原因需要更换同款产品。

      </div>
      <br />我们竭诚为您提供优质的服务，呈现独特的美衣，但美衣的呈现也绝不是偶然，需要历经若干工序的精打细磨，每一道工序都凝聚无数人的努力付出。
      同时，由于服装产品的属性和工艺限制，产品会存在一些允差、漏剪掉的线头、个别产品因为面料生产过程中交接处的线结等等，我们努力将其控制在国家行业标准范围内。如果介意，请慎拍。

      <br />
      <br /> <br /> <br /> <br />
      <div class="zrllastbox">
        感谢您对seooocookie的支持和厚爱！ <br />
        祝您生活愉快！
      </div>
    </div>
  </div>
</template>

<script>
import Logodia from "../../components/Logodia.vue";
export default {
  components: {
    Logodia
  }
};
</script>

<style lang="less" scoped>
.fontsize {
  font-size: 24px;
  font-family: PingFang;
  padding: 30px;
  letter-spacing: 2px;
  line-height: 45px;
}

.fontsize-title {
  text-align: center;
  font-weight: 700;
}

.lastbox {
  // margin-top: 50px;
  width: 100%;
  text-align: center;

  font-size: 28px;
  font-weight: 700;
}
.zrlcontent{
  display: none;
  width: 750px;
  margin:0 auto;
}


.zrlfontsize {
  font-size: 16px;
  font-family: PingFang;
  padding: 30px;
  letter-spacing: 2px;
  line-height: 45px;
}

.zrlfontsize-title {
  text-align: center;
  font-weight: 700;
}

.zrllastbox {
  // margin-top: 50px;
  width: 100%;
  text-align: center;

  font-size: 16px;
  font-weight: 700;
}
@media screen and (min-width: 1000px) {
  .lg{
    display: none;
  }
  .zrlcontent{
    display: block;
  }
}
</style>
<template>
 <div>
  <div class="lg">
    <!-- <Logodia></Logodia> -->
    <!-- <div class="zhanweiforfavorites"></div> -->
    <div class="favorriteshead">心愿单</div>
    <div class="content">
      <div class="mainbox" v-for="item in favorityList" :key="item.id" @click="toCommoditydetails(item.id)">
        <div class="mainimg">
          <van-image
            class="mainimg"
            :src="item.productMainImage"
            fit="cover"
            alt=""
          />
        </div>
        <div class="maininfobox">
          <div class="flexboxtwo">
            <div class="infonamebox">{{ item.productName }}</div>
            <div class="infotitlebox">{{ item.productBrief }}</div>
            <div class="skuinfo">{{ item.collect }}人收藏</div>
            <div class="flexbox">
              <div class="skuinfoprice">￥{{ item.unitPrice }}</div>
              <div class="cancelbtn" @click.stop="delCollect(item.id)">取消收藏</div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
  <div class="zrl zrlcontentbox">
    <!-- <Logodia></Logodia> -->
    <!-- <div class="zhanweiforfavorites"></div> -->
    <div class="zrlfavorriteshead">心愿单</div>
    <div class="zrlcontent">
      <div class="zrlmainbox" v-for="item in favorityList" :key="item.id" @click="toCommoditydetails(item.id)">
        <div class="zrlmainimg">
          <van-image
            class="zrlmainimg"
            :src="item.productMainImage"
            fit="cover"
            alt=""
          />
        </div>
        <div class="zrlmaininfobox">
          <div class="zrlflexboxtwo">
            <div class="zrlinfonamebox">{{ item.productName }}</div>
            <div class="zrlinfotitlebox">{{ item.productBrief }}</div>
            <div class="zrlskuinfo">{{ item.collect }}人收藏</div>
            <div class="zrlflexbox">
              <div class="zrlskuinfoprice">￥{{ item.unitPrice }}</div>
              <div class="zrlcancelbtn" @click.stop="delCollect(item.id)">取消收藏</div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
 </div>
</template>

<script>
import { collectList_api, delCollect_api } from "../../apis/product";
import Logodia from "../../components/Logodia.vue"
export default {
  name: "favorites",
  components:{
    Logodia
  },
  data() {
    return {
      favorityList: [],
      searchData: {
        pageNumber: 1,
        pageSize: 999,
      },
    };
  },
  created() {
    this.getFavorityList();
  },
  methods: {
    async getFavorityList() {
      let { code, data } = await collectList_api(this.searchData);
      if (code == 0) {
        this.favorityList = data.rows;
      }
    },
    async delCollect(id) {
      let { code, data } = await delCollect_api({
        productId: id,
      });
      if (code == 0) {
        this.getFavorityList();
      }
    },
    toCommoditydetails(val){
      this.$router.push(
        {
          path: "/commoditydetails",
          query: {
            commodityId: val,
          },
        },
        () => {}
      );

    }
  },
};
</script>

<style lang="less" scoped>
.zhanweiforfavorites {
  width: 100%;
  height: 70px;
}
.favorriteshead {
  margin-left: 30px;
  font-size: 30px;
  font-family: PingFang;
  font-weight: bold;
  color: #333333;
  margin-bottom: 50px;
}
.content {
  margin: 0 auto;
  width: 690px;
  padding-bottom: 100px;
}
.mainbox {
  padding: 30px;
  width: 630px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 20px;
  background: #fff;
}
.mainimg {
  width: 180px;
  height: 180px;
}
.maininfobox {
  width: 420px;
  height: 180px;
}
.flexboxtwo {
  height: 180px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}
.infonamebox {
  font-size: 24px;
  font-family: PingFang;
  font-weight: bold;
  color: #666666;
}
.infotitlebox {
  font-size: 24px;
  font-family: PingFang;
  font-weight: 500;
  color: #000000;
}
.skuinfo {
  font-size: 24px;
  font-family: PingFang;
  font-weight: 500;
  color: #999999;
}

.flexbox {
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.skuinfoprice {
  font-size: 30px;
  font-family: PingFang;
  font-weight: bold;
  color: #000000;
}
.cancelbtn {
  width: 100px;
  height: 40px;
  background: #ffffff;
  border: 1px solid #dedede;
  text-align: center;
  line-height: 40px;

  font-size: 20px;
  font-family: PingFang;
  font-weight: 500;
  color: #999999;
}
//pc
.zrlzhanweiforfavorites {
  width: 100%;
  height: 70px;
}
.zrlfavorriteshead {
  margin-left: 30px;
  font-size: 30px;
  font-family: PingFang;
  font-weight: bold;
  color: #333333;
  margin-bottom: 50px;
  width: 690px;
  margin: 0 auto;
  margin-bottom: 40px;
}
.zrlcontentbox{
  display: none;;
}
.zrlcontent {
  margin: 0 auto;
  width: 690px;
  padding-bottom: 100px;
 
}
.zrlmainbox {

  width: 630px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 20px;
  // background: #fff;
}
.zrlmainimg {
  width: 120px;
  height: 120px;
}
.zrlmaininfobox {
  width: 420px;
  height: 120px;
}
.zrlflexboxtwo {
  height: 120px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}
.zrlinfonamebox {
  font-size: 18px;
  font-family: PingFang;
  font-weight: bold;
  color: #666666;
}
.zrlinfotitlebox {
  font-size: 18px;
  font-family: PingFang;
  font-weight: 500;
  color: #000000;
}
.zrlskuinfo {
  font-size: 18px;
  font-family: PingFang;
  font-weight: 500;
  color: #999999;
}

.zrlflexbox {
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.zrlskuinfoprice {
  font-size: 20px;
  font-family: PingFang;
  font-weight: bold;
  color: #000000;
}
.zrlcancelbtn {
  width: 80px;
  height: 40px;
  background: #ffffff;
  border: 1px solid #dedede;
  text-align: center;
  line-height: 40px;

  font-size: 16px;
  font-family: PingFang;
  font-weight: 500;
  color: #999999;
}
@media screen and (min-width: 1000px) {
  .lg{
    display: none;
  }
  .zrlcontentbox{
    display: block;
  }
}
</style>
import { render, staticRenderFns } from "./Applysaledorder.vue?vue&type=template&id=47fb6fa4&scoped=true&"
import script from "./Applysaledorder.vue?vue&type=script&lang=js&"
export * from "./Applysaledorder.vue?vue&type=script&lang=js&"
import style0 from "./Applysaledorder.vue?vue&type=style&index=0&id=47fb6fa4&prod&lang=less&scoped=true&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "47fb6fa4",
  null
  
)

export default component.exports
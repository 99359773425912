<template>
  <div>
    <!-- <Logodia></Logodia> -->
    <!-- <div class="zhanweifororderlist"></div> -->
    <div class="lg">
      <div class="content">
        <div class="orderhead">全部订单</div>
        <div class="searchbox">
          <div class="flexbox">
            <div class="searchleftlable">状态</div>
            <div class="searchatypebox" @click="openPup">
              <div class="flexbox">
                <div>
                  {{ typeLable }}
                </div>
                <div>
                  <img class="xialaicon" src="../../assets/imgs/xiala@3x.png" alt="" />
                </div>
              </div>
            </div>
          </div>
          <div class="flexbox margintop20">
            <div class="searchleftlable">期间</div>
            <div class="wid543 flexbox">
              <div :class="[
                searchData.month === 0 ? 'checksearchtime' : 'unchecksearchtime',
              ]" @click="changeSearchMonth(0)">
                今天
              </div>
              <div :class="[
                searchData.month == 1 ? 'checksearchtime' : 'unchecksearchtime',
              ]" @click="changeSearchMonth(1)">
                1个月
              </div>
              <div :class="[
                searchData.month == 3 ? 'checksearchtime' : 'unchecksearchtime',
              ]" @click="changeSearchMonth(3)">
                3个月
              </div>
              <div :class="[
                searchData.month == 6 ? 'checksearchtime' : 'unchecksearchtime',
              ]" @click="changeSearchMonth(6)">
                6个月
              </div>
            </div>
          </div>
        </div>
        <van-list v-model="loading" :finished="finished" finished-text="没有更多了" @load="onLoad">
          <div class="mainbox" v-for="item in orderList" :key="item.id" @click="toOrderInfo(item.id)">
            <div class="maintime">{{ item.createTime }}</div>
            <div class="flexbox margintop20 typefont">
              <div v-if="searchData.status != 4">订单号：{{ item.orderNum }}</div>
              <div v-if="searchData.status == 4">订单号：{{ item.saleNo }}</div>
              <div>{{ item.status | formartStatus }}</div>
            </div>
            <div class="goodsinfobox flexbox" v-for="(ctem, index) in item.productList" :key="index">
              <div>
                <img class="mainimg" :src="ctem.productMainImage" alt="" />
              </div>
              <div class="mainiteninfobox">
                <div class="flexboxtwo">
                  <div class="infonamebox">{{ ctem.productName }}</div>
                  <div class="infotitlebox"></div>
                  <div class="skuinfo">
                    <div class="skuinfoitem" v-for="(etem, i) in ctem.productSkuName" :key="i">
                      {{ etem }}
                    </div>
                  </div>
                  <div class="flexbox">
                    <div class="skuinfoprice">￥{{ ctem.unitPrice }}</div>
                    <div class="skunum">x{{ ctem.num }}</div>
                  </div>
                </div>
              </div>
            </div>
            <div class="mainpricez">
              <span class="mainpricelable">共 {{ item.num }} 件 合计：</span>
              <span class="mainpricenum" v-if="searchData.status != 4">
                ￥{{ item.paidAmount }}
              </span>
              <span class="mainpricenum" v-if="searchData.status == 4">
                ￥{{ item.amount }}
              </span>
            </div>
            <div class="flexboxthree" v-if="searchData.status != 4">
              <div class="mainbtntwo" v-if="item.status == 2" @click.stop="confirmOrder(item.id)">
                确认收货
              </div>
              <div class="mainbtnone" v-if="item.status == 2 || item.status == 6" @click.stop="getLogistics(item.id)">
                查看物流
              </div>

              <div class="mainbtnone" v-if="item.status == 3 || item.status == 6 || item.status == 5"
                @click.stop="delOrder(item.id)">
                删除订单
              </div>
              <div class="mainbtntwo" v-if="item.status == 0">立即支付</div>
              <div class="mainbtnone" v-if="item.status == 0" @click.stop="cancelOrder(item.id)">
                取消订单
              </div>
            </div>
            <div class="flexboxthree" v-if="searchData.status == 4">
              <div class="mainbtntwo">查看详情</div>
              <div class="mainbtnone" @click.stop="delSaleOrder(item.id)" v-if="item.status != 0">
                删除记录
              </div>
            </div>
          </div>
        </van-list>
      </div>
      <van-action-sheet v-model="showPup" :actions="typeList" cancel-text="取消" close-on-click-action @cancel="onCancel"
        @select="onConfirm" />
      <van-popup v-model="showHaisiyPop" round closeable close-icon-position="top-left" safe-area-inset-bottom
        position="bottom" :style="{ height: '70%' }">
        <div>
          <div class="zhanweiforhaisiy"></div>

          <van-tabs v-model="activeTab" animated>
            <van-tab v-for="(item, index) in haisiyList" :title="item.name" :key="index">
              <div>
                <div class="mainboxtwo">

                  <div class="marginb5" v-for="(etem, j) in item.productList" :key="j">
                    <div class="mainitembox">
                      <div>
                        <img class="mainimg" :src="etem.productMainImage" alt="" />
                      </div>
                      <div class="mainiteninfobox">
                        <div class="flexboxtwo">
                          <div class="infonamebox">{{ etem.productName }}</div>
                          <div class="infotitlebox"></div>
                          <div class="skuinfo" v-if="etem.productSkuName">
                            <div class="skuinfoitem" v-for="(ctem, index) in etem.productSkuName" :key="index">
                              {{ ctem }}
                            </div>
                          </div>
                          <div class="flexbox">
                            <div class="skuinfoprice">￥{{ etem.unitPrice }}</div>
                            <div class="skunum">x{{ etem.num }}</div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>

                </div>
                <div class="flexbox bottomline">
                  <div class="expNamebox">
                    {{ item.logisticsHtml.expName }}
                    {{ item.logisticsHtml.number }}
                  </div>
                  <div class="p-r-30">
                    <span class="copybtntwo" @click="copyexpNumber(item.logisticsHtml.number)">
                      复制
                    </span>
                    <span> | </span>
                    <span class="callbtn" v-if="item.logisticsHtml.courierPhone"
                      @click="callPhone(item.logisticsHtml.courierPhone)">
                      打电话
                    </span>
                  </div>
                </div>



                <van-steps direction="vertical" :active="0">
                  <van-step v-for="(ctem, i) in item.logisticsHtml.list" :key="i">
                    <div class="haisiyInfolable">{{ ctem.status }}</div>
                    <div>{{ ctem.time }}</div>
                  </van-step>
                </van-steps>
              </div>
            </van-tab>
          </van-tabs>



          <van-steps direction="vertical" :active="0">
            <van-step v-for="(item, index) in haisiyInfo.list" :key="index">
              <div class="haisiyInfolable">{{ item.status }}</div>
              <div>{{ item.time }}</div>
            </van-step>
          </van-steps>
        </div>
      </van-popup>
    </div>
    <div class="zrl zrlbox">
      <div class="zrlcontent">
        <div class="zrlorderhead">全部订单</div>
        <div class="zrlsearchbox">
          <div class="zrlflexbox">
            <div class="zrlsearchleftlable">状态</div>
            <!-- <div class="zrlsearchatypebox" @click="openPup">
              <div class="zrlflexbox">
                <div>
                  {{ typeLable }}
                </div>
                <div>
                  <img class="zrlxialaicon" src="../../assets/imgs/xiala@3x.png" alt="" />
                </div>
              </div>
            </div> -->

            <div>
              <el-select v-model="typeLable" placeholder="请选择" @change="pconConfirm($event)">
                <el-option v-for="item in typeList" :key="item.value" :label="item.name" :value="item.value">
                </el-option>
              </el-select>
            </div>
          </div>
          <div class="zrlflexbox zrlmargintop20">
            <div class="zrlsearchleftlable">期间</div>
            <div class="zrlwid543 zrlflexbox">
              <div :class="[
                searchData.month === 0 ? 'zrlchecksearchtime' : 'zrlunchecksearchtime',
              ]" @click="changeSearchMonth(0)">
                今天
              </div>
              <div :class="[
                searchData.month == 1 ? 'zrlchecksearchtime' : 'zrlunchecksearchtime',
              ]" @click="changeSearchMonth(1)">
                1个月
              </div>
              <div :class="[
                searchData.month == 3 ? 'zrlchecksearchtime' : 'zrlunchecksearchtime',
              ]" @click="changeSearchMonth(3)">
                3个月
              </div>
              <div :class="[
                searchData.month == 6 ? 'zrlchecksearchtime' : 'zrlunchecksearchtime',
              ]" @click="changeSearchMonth(6)">
                6个月
              </div>
            </div>
          </div>
        </div>
        <van-list v-model="loading" :finished="finished" finished-text="没有更多了" @load="onLoad">
          <div class="zrlmainbox" v-for="item in orderList" :key="item.id" @click="toOrderInfo(item.id)">
            <div style="width: 500px;margin-right: 50px;">
              <div class="zrlmaintime">{{ item.createTime }}</div>
              <div class="zrlflexbox zrlmargintop20 zrltypefont">
                <div v-if="searchData.status != 4">订单号：{{ item.orderNum }}</div>
                <div v-if="searchData.status == 4">订单号：{{ item.saleNo }}</div>
                <div>{{ item.status | formartStatus }}</div>
              </div>
              <div class="zrlgoodsinfobox zrlflexbox" v-for="(ctem, index) in item.productList" :key="index">
                <div>
                  <img class="zrlmainimg" :src="ctem.productMainImage" alt="" />
                </div>
                <div class="zrlmainiteninfobox">
                  <div class="zrlflexboxtwo">
                    <div class="zrlinfonamebox">{{ ctem.productName }}</div>

                    <div class="zrlskuinfo">
                      <div class="zrlskuinfoitem" v-for="(etem, i) in ctem.productSkuName" :key="i">
                        {{ etem }}
                      </div>
                    </div>
                    <div class="zrlflexbox">
                      <div class="zrlskuinfoprice">￥{{ ctem.unitPrice }}</div>
                      <div class="zrlskunum">x{{ ctem.num }}</div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div>
              <div class="zrlmainpricez">
                <span class="zrlmainpricelable">共 {{ item.num }} 件 合计：</span>
                <span class="zrlmainpricenum" v-if="searchData.status != 4">
                  ￥{{ item.paidAmount }}
                </span>
                <span class="zrlmainpricenum" v-if="searchData.status == 4">
                  ￥{{ item.amount }}
                </span>
              </div>
              <div class="zrlflexboxthree" v-if="searchData.status != 4">
                <div class="zrlmainbtntwo" v-if="item.status == 2" @click.stop="confirmOrder(item.id)">
                  确认收货
                </div>
                <div class="zrlmainbtnone" v-if="item.status == 2 || item.status == 6"
                  @click.stop="getLogistics(item.id)">
                  查看物流
                </div>

                <div class="zrlmainbtnone" v-if="item.status == 3 || item.status == 6 || item.status == 5"
                  @click.stop="delOrder(item.id)">
                  删除订单
                </div>
                <div class="zrlmainbtntwo" v-if="item.status == 0">立即支付</div>
                <div class="zrlmainbtnone" v-if="item.status == 0" @click.stop="cancelOrder(item.id)">
                  取消订单
                </div>
              </div>
              <div class="zrlflexboxthree" v-if="searchData.status == 4">
                <div class="zrlmainbtntwo">查看详情</div>
                <div class="zrlmainbtnone" @click.stop="delSaleOrder(item.id)" v-if="item.status != 0">
                  删除记录
                </div>
              </div>
            </div>

          </div>
        </van-list>
      </div>
      <van-action-sheet v-model="showPup" :actions="typeList" cancel-text="取消" close-on-click-action @cancel="onCancel"
        @select="onConfirm" />
      <van-popup v-model="showHaisiyPop" round closeable close-icon-position="top-left" safe-area-inset-bottom
        :style="{ height: '70%' }">
        <div>
          <div class="zrlzhanweiforhaisiy"></div>

          <van-tabs v-model="activeTab" animated>
            <van-tab v-for="(item, index) in haisiyList" :title="item.name" :key="index">
              <div>
                <div class="zrlmainboxtwo">

                  <div class="zrlmarginb5" v-for="(etem, j) in item.productList" :key="j">
                    <div class="zrlmainitembox">
                      <div>
                        <img class="zrlmainimg" :src="etem.productMainImage" alt="" />
                      </div>
                      <div class="zrlmainiteninfobox">
                        <div class="zrlflexboxtwo">
                          <div class="zrlinfonamebox">{{ etem.productName }}</div>
                          <div class="zrlinfotitlebox"></div>
                          <div class="zrlskuinfo" v-if="etem.productSkuName">
                            <div class="zrlskuinfoitem" v-for="(ctem, index) in etem.productSkuName" :key="index">
                              {{ ctem }}
                            </div>
                          </div>
                          <div class="zrlflexbox">
                            <div class="zrlskuinfoprice">￥{{ etem.unitPrice }}</div>
                            <div class="zrlskunum">x{{ etem.num }}</div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>

                </div>
                <div class="zrlflexbox zrlbottomline">
                  <div class="zrlexpNamebox">
                    {{ item.logisticsHtml.expName }}
                    {{ item.logisticsHtml.number }}
                  </div>
                  <div class="p-r-30">
                    <span class="zrlcopybtntwo" @click="copyexpNumber(item.logisticsHtml.number)">
                      复制
                    </span>
                    <span> | </span>
                    <span class="zrlcallbtn" v-if="item.logisticsHtml.courierPhone"
                      @click="callPhone(item.logisticsHtml.courierPhone)">
                      打电话
                    </span>
                  </div>
                </div>



                <van-steps direction="vertical" :active="0">
                  <van-step v-for="(ctem, i) in item.logisticsHtml.list" :key="i">
                    <div class="zrlhaisiyInfolable">{{ ctem.status }}</div>
                    <div>{{ ctem.time }}</div>
                  </van-step>
                </van-steps>
              </div>
            </van-tab>
          </van-tabs>



          <van-steps direction="vertical" :active="0">
            <van-step v-for="(item, index) in haisiyInfo.list" :key="index">
              <div class="zrlhaisiyInfolable">{{ item.status }}</div>
              <div>{{ item.time }}</div>
            </van-step>
          </van-steps>
        </div>
      </van-popup>
    </div>

  </div>
</template>

<script>
import {
  orderList_api,
  saleOrderList_api,
  delOrder_api,
  cancelOrder_api,
  haisiyInfo_api,
  confirmOrder_api,
  delSaleOrder_api,
} from "../../apis/order";
import Logodia from "../../components/Logodia.vue"
let searchDataStatus;
export default {
  components: {
    Logodia
  },
  data() {
    return {
      searchData: {
        status: "",
        month: '',
        pageNumber: 1,
        pageSize: 1,
      },
      typeList: [
        { name: "全部", value: "" },
        { name: "待付款", value: "0" },
        { name: "待发货", value: "1" },
        { name: "待收货", value: "2" },
        { name: "已收货", value: "3" },
        { name: "退货/售后", value: "4" },
        { name: "已取消", value: "5" },
        { name: "部分发货", value: "6" },
      ],
      typeLable: "全部",
      showPup: false,
      loading: false,
      finished: false,
      orderList: [],
      haisiyInfo: "",
      showHaisiyPop: false,
      haisiyList: [],
      activeTab: 0,

    };
  },
  created() {
    if (this.$route.query.type) {
      this.searchData.status = this.$route.query.type;
      searchDataStatus = this.$route.query.type;
      if (this.searchData.status == 0) {
        this.typeLable = "待付款";
      } else if (this.searchData.status == 1) {
        this.typeLable = "待发货";
      } else if (this.searchData.status == 2) {
        this.typeLable = "待收货";
      } else if (this.searchData.status == 4) {
        this.typeLable = "退货/售后";
      }
      this.searchFn();
    } else {
      this.searchFn();
    }

  },
  filters: {
    formartStatus(val) {
      if (searchDataStatus == 4) {
        if (val == 0) {
          return "处理中";
        } else if (val == 1) {
          return "审核通过";
        } else if (val == 2) {
          return "审核拒绝";
        } else if (val == 3) {
          return "已取消";
        }
      } else {
        if (val == 0) {
          return "待付款";
        } else if (val == 1) {
          return "待发货";
        } else if (val == 2) {
          return "待收货";
        } else if (val == 3) {
          return "已收货";
        } else if (val == 4) {
          return "已售后";
        } else if (val == 5) {
          return "已取消";
        } else if (val == 6) {
          return "部分发货";
        } else if (val == 7) {
          return "全部退货";
        }
      }
    },
  },
  methods: {
    toOrderInfo(val) {
      let type;
      if (this.searchData.status == 4) {
        type = "saleOrder";
        this.$router.push(
          {
            path: "/saledorderinfo",
            query: {
              id: val,
              type: type,
            },
          },
          () => { }
        );
      } else {
        type = "detailOrder";
        this.$router.push(
          {
            path: "/orderinfo",
            query: {
              id: val,
              type: type,
            },
          },
          () => { }
        );
      }
    },

    openPup() {
      this.showPup = true;
    },
    onConfirm(val) {
      console.log(val);
      this.typeLable = val.name;
      this.searchData.status = val.value;
      searchDataStatus = val.value;
      this.searchFn();
    },
    pconConfirm(val) {
      if (val == '') {
        this.typeLable = '全部'
        this.searchData.status = ''
        this.searchData.status = ''
        this.searchFn();
      } else {
        this.typeList.map(v => {
          if (v.value == val) {
            this.typeLable = v.name;
            this.searchData.status = v.value;
            searchDataStatus = v.value;
            this.searchFn();
          }
        })
      }

    },
    onCancel() {
      this.showPup = false;
    },
    changeSearchMonth(type) {
      this.searchData.month = type;
      this.searchFn();
    },
    searchFn() {
      this.searchData.pageSize = 10;
      this.searchFnz();
    },
    searchFnz() {
      if (this.searchData.status == 4) {
        searchDataStatus = 4;
        this.getSaleOrderList();
      } else {
        searchDataStatus = 1;
        this.getOrderList();
      }
    },
    async getSaleOrderList() {
      let { code, data } = await saleOrderList_api(this.searchData);
      if (code == 0) {
        this.orderList = data.rows;
        this.total = data.total;
      }
    },
    async getOrderList() {
      let { code, data } = await orderList_api(this.searchData);
      if (code == 0) {
        this.orderList = data.rows;
        this.total = data.total;
      }
    },
    onLoad() {
      this.searchData.pageSize += 10;
      this.searchFnz();
      this.loading = false;
      if (this.orderList.length >= this.total) {
        this.finished = true;
      }
    },
    delOrder(id) {
      this.$dialog
        .confirm({
          message: "确定要删除吗？",
        })
        .then(() => {
          this.delOrderFn(id);
        });
    },
    async delOrderFn(id) {
      let { code, data } = await delOrder_api({
        id: id,
      });
      if (code == 0) {
        this.$toast.success("记录已删除");
        this.searchFnz();
      }
    },
    delSaleOrder(id) {
      this.$dialog
        .confirm({
          message: "确定要删除吗？",
        })
        .then(() => {
          this.delSaleOrderFn(id);
        });
    },
    async delSaleOrderFn(id) {
      let { code, data } = await delSaleOrder_api({
        id: id,
      });
      if (code == 0) {
        this.$toast.success("记录已删除");
        this.searchFnz();
      }
    },
    cancelOrder(id) {
      this.$dialog
        .confirm({
          message: "确定要取消这个订单吗？",
        })
        .then(() => {
          this.cancelOrderFn(id);
        });
    },
    async cancelOrderFn(id) {
      let { code, data } = await cancelOrder_api({
        id: id,
      });
      if (code == 0) {
        this.$toast.success("订单已取消");
        this.searchFnz();
      }
    },
    async getLogistics(id) {
      let { code, data, message } = await haisiyInfo_api({
        id: id,

      });
      if (code == 0) {
        this.haisiyList = data;
        this.activeTab = 0;
        // this.haisiyInfo = JSON.parse(message).result;
        this.haisiyList.forEach((item) => {
          let logisticsHtml = JSON.parse(item.logistics).result;
          this.$set(item, "logisticsHtml", logisticsHtml);
        });
        this.showHaisiyPop = true;
      }
    },
    confirmOrder(id) {
      this.$dialog
        .confirm({
          message: "确认已收到您的商品？",
        })
        .then(() => {
          this.confirmOrderFn(id);
        });
    },
    async confirmOrderFn(id) {
      let { code, data } = await confirmOrder_api({
        id: id,
      });
      if (code == 0) {
        this.$toast.success("欢迎下次光临~");
        this.searchFnz();
      }
    },
    copyexpNumber(num) {
      var input = document.createElement("input"); // 创建input对象
      input.value = num; // 设置复制内容
      document.body.appendChild(input); // 添加临时实例
      input.select(); // 选择实例内容
      document.execCommand("Copy"); // 执行复制
      document.body.removeChild(input); // 删除临时实例
      this.$toast.success("成功复制运单号");
    },
    callPhone(phone) {
      window.location.href = "tel:" + phone;
    },
  },
};
</script>

<style lang="less" scoped>
.zhanweifororderlist {
  width: 100%;
  height: 70px;
}

.content {
  margin: 0 auto;
  width: 690px;
}

.orderhead {
  font-size: 30px;
  font-family: PingFang;
  font-weight: bold;
  color: #333333;
  margin-bottom: 35px;
}

.searchbox {
  width: 650px;
  padding: 20px;
  background: #fff;
}

.flexbox {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.searchleftlable {
  font-size: 28px;
  font-family: PingFang;
  font-weight: bold;
  color: #333333;
}

.searchatypebox {
  width: 473px;
  height: 60px;
  background: #ffffff;
  border: 1px solid #e3e3e3;
  line-height: 60px;
  padding-left: 30px;
  padding-right: 40px;

  font-size: 28px;
  font-family: PingFang;
  font-weight: 500;
  color: #666666;
}

.marginleft30 {
  margin-left: 30px;
}

.xialaicon {
  width: 20px;
  height: 14px;
}

.margintop20 {
  margin-top: 20px;
}

.wid543 {
  width: 543px;
}

.unchecksearchtime {
  width: 126px;
  height: 60px;
  background: #ffffff;
  border: 1px solid #e3e3e3;
  line-height: 60px;
  text-align: center;

  font-size: 28px;
  font-family: PingFang;
  font-weight: 500;
  color: #666666;
}

.checksearchtime {
  width: 125px;
  height: 60px;
  background: #986665;

  line-height: 60px;
  text-align: center;

  font-size: 28px;
  font-family: PingFang;
  font-weight: 500;
  color: #ffffff;
}

.mainbox {
  width: 630px;
  padding: 30px;

  background: #ffffff;
  border: 1px solid #dddddd;
  margin-top: 20px;
}

.mainboxtwo {
  width: 630px;
  padding: 40px 30px;
  margin-bottom: 20px;
  background: #fff;
}

.marginb5 {
  margin-bottom: 10px;
}

.mainitembox {
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-wrap: wrap;
  // margin-bottom: 20px;
}

.maintime {
  font-size: 24px;
  font-family: PingFang;
  font-weight: bold;
  color: #999999;
}

.typefont {
  font-size: 26px;
  font-family: PingFang;
  font-weight: bold;
  color: #000000;
}

.goodsinfobox {
  padding-bottom: 40px;
  border-bottom: 1px dashed #c4c4c4;
  margin-top: 40px;
}

.mainimg {
  width: 180px;
  height: 180px;
  border-radius: 10px;
  object-fit: cover;
}

.mainiteninfobox {
  width: 420px;
  height: 180px;
}

.flexboxtwo {
  height: 180px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.infonamebox {
  font-size: 24px;
  font-family: PingFang;
  font-weight: bold;
  color: #666666;
}

.infotitlebox {
  font-size: 24px;
  font-family: PingFang;
  font-weight: 500;
  color: #000000;
}

.skuinfo {
  font-size: 24px;
  font-family: PingFang;
  font-weight: 500;
  color: #999999;
}

.skuinfoitem {
  display: inline-block;
  vertical-align: top;
  margin-right: 10px;
}

.skuinfoprice {
  font-size: 30px;
  font-family: PingFang;
  font-weight: bold;
  color: #000000;
}

.skunum {
  font-size: 24px;
  font-family: PingFang;
  font-weight: bold;
  color: #000000;
  letter-spacing: 3px;
}

.mainpricez {
  margin-top: 27px;
  margin-bottom: 22px;
  text-align: right;
}

.mainpricelable {
  font-size: 24px;
  font-family: PingFang;
  font-weight: bold;
  color: #000000;
}

.mainpricenum {
  margin-left: 10px;
  font-size: 28px;
  font-family: Heiti SC;
  font-weight: 500;
  color: #000000;
}

.mainbtnone {
  width: 300px;
  height: 80px;
  background: #ffffff;
  border: 1px solid #eeeeee;
  line-height: 80px;
  text-align: center;

  font-size: 24px;
  font-family: PingFang;
  font-weight: bold;
  color: #000000;
}

.mainbtntwo {
  width: 300px;
  height: 80px;
  background: #986665;
  line-height: 80px;
  text-align: center;

  font-size: 24px;
  font-family: PingFang;
  font-weight: bold;
  color: #ffffff;
}

.flexboxthree {
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  // flex-wrap: wrap-reverse;
  flex-direction: row-reverse;
}

.haisiyInfolable {
  font-size: 24px;
  font-family: PingFang;
  font-weight: 500;
  //color: #666666;
}

.zhanweiforhaisiy {
  width: 100%;
  height: 50px;
}

.bottomline {
  border-bottom: 1px solid #eeeeee;
  padding-bottom: 20px;
}

.expNamebox {
  margin-left: 30px;
  font-size: 28px;
}

.copybtntwo {
  font-size: 28px;
  font-family: PingFang SC;
  margin-right: 10px;
}

.callbtn {
  font-size: 28px;
  font-family: PingFang SC;
  margin-left: 10px;
}

//pc
.zrlzhanweifororderlist {
  width: 100%;
  height: 70px;
}

.zrlcontent {
  margin: 0 auto;
  width: 900px;
}

.zrlorderhead {
  font-size: 30px;
  font-family: PingFang;
  font-weight: bold;
  color: #333333;
  margin-bottom: 35px;
}

.zrlsearchbox {
  width: 650px;
  padding: 20px;
  // background: #fff;
}

.zrlflexbox {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.zrlsearchleftlable {
  font-size: 28px;
  font-family: PingFang;
  font-weight: bold;
  color: #333333;
}

.zrlsearchatypebox {
  width: 473px;
  height: 60px;
  background: #ffffff;
  border: 1px solid #e3e3e3;
  line-height: 60px;
  padding-left: 30px;
  padding-right: 40px;

  font-size: 28px;
  font-family: PingFang;
  font-weight: 500;
  color: #666666;
}

.zrlmarginleft30 {
  margin-left: 30px;
}

.zrlxialaicon {
  width: 20px;
  height: 14px;
}

.zrlmargintop20 {
  margin-top: 0px;
}

.zrlwid543 {
  width: 543px;
}

.zrlunchecksearchtime {
  width: 126px;
  height: 60px;
  background: #ffffff;
  border: 1px solid #e3e3e3;
  line-height: 60px;
  text-align: center;

  font-size: 28px;
  font-family: PingFang;
  font-weight: 500;
  color: #666666;
  cursor: pointer;
}

.zrlchecksearchtime {
  width: 125px;
  height: 60px;
  background: #986665;

  line-height: 60px;
  text-align: center;

  font-size: 28px;
  font-family: PingFang;
  font-weight: 500;
  color: #ffffff;
  cursor: pointer;
}

.zrlmainbox {
  width: 900px;
  padding: 5px;
  display: flex;

  // background: #ffffff;
  border: 1px solid #dddddd;
  margin-top: 20px;
}

.zrlmainboxtwo {
  width: 630px;
  padding: 40px 30px;
  margin-bottom: 20px;
  background: #fff;
}

.zrlmarginb5 {
  margin-bottom: 10px;
}

.zrlmainitembox {
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-wrap: wrap;
  // margin-bottom: 20px;
}

.zrlmaintime {
  font-size: 24px;
  font-family: PingFang;
  font-weight: bold;
  color: #999999;
}

.zrltypefont {
  font-size: 26px;
  font-family: PingFang;
  font-weight: bold;
  color: #000000;
}

.zrlgoodsinfobox {
  padding-bottom: 20px;
  // border-bottom: 1px dashed #c4c4c4;
  margin-top: 20px;
}

.zrlmainimg {
  width: 120px;
  height: 120px;
  border-radius: 10px;
  object-fit: cover;
  margin-right: 20px;
}

.zrlmainiteninfobox {
  width: 420px;
  height: 120px;
}

.zrlflexboxtwo {
  height: 120px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.zrlinfonamebox {
  font-size: 24px;
  font-family: PingFang;
  font-weight: bold;
  color: #666666;
}

.zrlinfotitlebox {
  font-size: 24px;
  font-family: PingFang;
  font-weight: 500;
  color: #000000;
}

.zrlskuinfo {
  font-size: 24px;
  font-family: PingFang;
  font-weight: 500;
  color: #999999;
}

.zrlskuinfoitem {
  display: inline-block;
  vertical-align: top;
  margin-right: 10px;
}

.zrlskuinfoprice {
  font-size: 30px;
  font-family: PingFang;
  font-weight: bold;
  color: #000000;
}

.zrlskunum {
  font-size: 24px;
  font-family: PingFang;
  font-weight: bold;
  color: #000000;
  letter-spacing: 3px;
}

.zrlmainpricez {
  margin-top: 30px;
  margin-bottom: 22px;
  // text-align: right;
}

.zrlmainpricelable {
  font-size: 24px;
  font-family: PingFang;
  font-weight: bold;
  color: #000000;
}

.zrlmainpricenum {
  margin-left: 10px;
  font-size: 28px;
  font-family: Heiti SC;
  font-weight: 500;
  color: #000000;
}

.zrlmainbtnone {
  width: 150px;
  height: 40px;
  background: #ffffff;
  border: 1px solid #eeeeee;
  line-height: 40px;
  text-align: center;
  font-size: 20px;
  font-family: PingFang;
  font-weight: bold;
  color: #000000;
  cursor: pointer;
  margin-top: 10px;
}

.zrlmainbtntwo {
  width: 150px;
  height: 40px;
  background: #986665;
  line-height: 40px;
  text-align: center;

  font-size: 20px;
  font-family: PingFang;
  font-weight: bold;
  color: #ffffff;
  cursor: pointer;
  margin-top: 10px;
}

.zrlflexboxthree {
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: end;
}

.zrlhaisiyInfolable {
  font-size: 24px;
  font-family: PingFang;
  font-weight: 500;
  //color: #666666;
}

.zrlzhanweiforhaisiy {
  width: 100%;
  height: 50px;
}

.zrlbottomline {
  border-bottom: 1px solid #eeeeee;
  padding-bottom: 20px;
}

.zrlexpNamebox {
  margin-left: 30px;
  font-size: 28px;
}

.zrlcopybtntwo {
  font-size: 28px;
  font-family: PingFang SC;
  margin-right: 10px;
  cursor: pointer;
}

.zrlcallbtn {
  font-size: 28px;
  font-family: PingFang SC;
  margin-left: 10px;
}

.zrlbox {
  display: none;
}

@media screen and (min-width: 1000px) {
  .lg {
    display: none;
  }

  .zrlbox {
    display: block;
  }
}
</style>
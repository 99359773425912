<template>

  <div>
    <div class="lg content">
      <!-- <Logodia></Logodia> -->
      <!-- <div class="zhanweiforvip"></div> -->
      <div class="mainbox">
        <div class="mainheadbox">我的积分</div>
        <div class="flexbox">
          <div>
            <div class="intergrallable">当前积分</div>
            <div class="intergralnum">{{ userInfo.integral }}</div>
          </div>
          <div>
            <div class="intergrallable">已消耗</div>
            <div class="intergralnum">{{ userInfo.useintegral }}</div>
          </div>
        </div>
      </div>
      <div class="mainbox margintop20">
        <div class="flexboxtwo">
          <div class="mingxititle">积分明细</div>
          <div class="monthinput" @click="openCalendar">
            {{ intergralInfo.month }}
            <img class="xialaicon" src="../../assets/imgs/xiala@3x.png" alt="" />
          </div>
        </div>
        <div class="flexboxtwo margintop40" v-for="item in integralList" :key="item.id">
          <div>
            <div class="inlineblock jifenicon">
              <img class="jifenicon" src="../../assets/imgs/jifen@3x.png" alt="" />
            </div>
            <div class="inlineblock">
              <div class="jinfenname">{{ item.content }}</div>
              <div class="jifentime">
                <span v-if="item.type == 1">获得积分</span>
                <span v-if="item.type == 0">消耗积分</span>
              </div>
            </div>
          </div>
          <div>
            <div class="jifennum">
              <span v-if="item.type == 1">+</span>
              <span v-if="item.type == 0">-</span>
              {{ item.integral }}
            </div>
            <div class="jifentime">{{ item.createTime }}</div>
          </div>
        </div>
      </div>
      <van-popup v-model="showCalendar" position="bottom" :style="{ height: '40%' }">
        <van-datetime-picker v-model="currentTime" type="year-month" title="选择日期" :min-date="minDate"
          :max-date="maxDate" @confirm="onConfirm" @cancel="onCancel" />
      </van-popup>
    </div>
    <div class="zrl zrlcontent">
      <!-- <Logodia></Logodia> -->
      <!-- <div class="zhanweiforvip"></div> -->
      <div class="zrlmainbox">
        <div class="zrlmainheadbox">我的积分</div>
        <div class="zrlflexbox">
          <div>
            <div class="zrlintergrallable">当前积分</div>
            <div class="zrlintergralnum">{{ userInfo.integral }}</div>
          </div>
          <div>
            <div class="zrlintergrallable">已消耗</div>
            <div class="zrlintergralnum">{{ userInfo.useintegral }}</div>
          </div>
        </div>
      </div>
      <div class="zrlmainbox zrlmargintop20">
        <div class="zrlflexboxtwo">
          <div class="zrlmingxititle">积分明细</div>
          <!-- <div class="zrlmonthinput" @click="openCalendar">
            {{ intergralInfo.month }}
            <img class="zrlxialaicon" src="../../assets/imgs/xiala@3x.png" alt="" />
          </div> -->
          <el-date-picker v-model="intergralInfo.month"  value-format="yyyy-MM"  @change="changemonth" type="month" placeholder="选择月">
          </el-date-picker>
        </div>
        <div class="zrlflexboxtwo zrlmargintop40" v-for="item in integralList" :key="item.id">
          <div>
            <div class="zrlinlineblock zrljifenicon">
              <img class="zrljifenicon" src="../../assets/imgs/jifen@3x.png" alt="" />
            </div>
            <div class="zrlinlineblock">
              <div class="zrljinfenname">{{ item.content }}</div>
              <div class="zrljifentime">
                <span v-if="item.type == 1">获得积分</span>
                <span v-if="item.type == 0">消耗积分</span>
              </div>
            </div>
          </div>
          <div>
            <div class="zrljifennum">
              <span v-if="item.type == 1">+</span>
              <span v-if="item.type == 0">-</span>
              {{ item.integral }}
            </div>
            <div class="zrljifentime">{{ item.createTime }}</div>
          </div>
        </div>
      </div>
      <van-popup v-model="showCalendar" position="bottom" :style="{ height: '40%' }">
        <van-datetime-picker v-model="currentTime" type="year-month" title="选择日期" :min-date="minDate"
          :max-date="maxDate" @confirm="onConfirm" @cancel="onCancel" />
      </van-popup>
    </div>
  </div>

</template>

<script>
import { userInfo_api, integralList_api } from "../../apis/mine";
import Logodia from "../../components/Logodia.vue"
export default {
  components: {
    Logodia
  },
  data() {
    return {
      showCalendar: false,
      minDate: new Date(1900, 0, 1),
      maxDate: new Date(),
      currentTime: new Date(),
      intergralInfo: {
        month: "",
        pageNumber: 1,
        pageSize: 9999,
      },
      userInfo: "",
      integralList: [],


    };
  },
  created() {
    let date = new Date();
    let y = date.getFullYear();
    let m = date.getMonth() + 1;
    if (m < 10) {
      m = "0" + m;
    }
    this.intergralInfo.month = y + "-" + m;
    this.getUserInfo();
    this.getIntegralList();
  },
  methods: {
    openCalendar() {
      this.showCalendar = true;
    },
    onConfirm(date) {
      console.log(date);
      this.showCalendar = false;
      let y = date.getFullYear();
      let m = date.getMonth() + 1;
      if (m < 10) {
        m = "0" + m;
      }
      let d = date.getDate();
      if (d < 10) {
        d = "0" + d;
      }
      this.intergralInfo.month = y + "-" + m;
      this.getIntegralList()
    },
    changemonth(){
      this.getIntegralList()
    },
    onCancel() {
      this.showCalendar = false;
    },
    async getUserInfo() {
      let { code, data } = await userInfo_api();
      if (code == 0) {
        this.userInfo = data;


      }
    },
    async getIntegralList() {
      let { code, data } = await integralList_api(this.intergralInfo);
      if (code == 0) {
        this.integralList = data.rows;
      }
    },
  },
};
</script>

<style lang="less" scoped>
.content {
  margin: 0px auto;
  width: 690px;
}

.zhanweiforvip {
  width: 100%;
  height: 100px;
}

.mainbox {
  padding: 30px 20px 70px 20px;
  background: #fff;
}

.mainheadbox {
  font-size: 30px;
  font-family: PingFang;
  font-weight: bold;
  color: #333333;
  padding-bottom: 30px;
  border-bottom: 1px solid #e8e8e8;
  margin-bottom: 50px;
}

.flexbox {
  display: flex;
  justify-content: space-around;
  align-items: center;
}

.intergrallable {
  font-size: 28px;
  font-family: PingFang;
  font-weight: 500;
  color: #666666;
  text-align: center;
}

.intergralnum {
  font-size: 28px;
  font-family: Heiti SC;
  font-weight: 500;
  color: #333333;
  margin-top: 30px;
  text-align: center;
}

.margintop20 {
  margin-top: 20px;
}

.flexboxtwo {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.mingxititle {
  font-size: 30px;
  font-family: PingFang;
  font-weight: bold;
  color: #333333;
}

.monthinput {
  width: 190px;
  height: 50px;
  background: #ffffff;
  border: 1px solid #e3e3e3;
  padding-left: 30px;
  line-height: 50px;
  position: relative;
  font-size: 28px;
  font-family: PingFang;
  font-weight: 500;
  color: #666666;
}

.xialaicon {
  width: 20px;
  height: 14px;
  position: absolute;
  top: 18px;
  right: 30px;
}

.margintop40 {
  margin-top: 40px;
}

.jifenicon {
  width: 29px;
  height: 29px;
  margin-right: 8px;
}

.inlineblock {
  display: inline-block;
  vertical-align: top;
}

.jinfenname {
  font-size: 24px;
  font-family: PingFang;
  font-weight: 500;
  color: #333333;
}

.jifentime {
  font-size: 22px;
  font-family: PingFang;
  font-weight: 500;
  color: #8c94a1;
}

.jifennum {
  font-size: 24px;
  font-family: Heiti SC;
  font-weight: 500;
  color: #010101;
  text-align: right;
}

//pc
.zrlcontent {
  margin: 0px auto;
  width: 690px;
  display: none;
}

.zrlzhanweiforvip {
  width: 100%;
  height: 100px;
}

.zrlmainbox {
  padding: 30px 20px 70px 20px;
  // background: #fff;
}

.zrlmainheadbox {
  font-size: 30px;
  font-family: PingFang;
  font-weight: bold;
  color: #333333;
  padding-bottom: 30px;
  border-bottom: 1px solid #e8e8e8;
  margin-bottom: 50px;
}

.zrlflexbox {
  display: flex;
  justify-content: space-around;
  align-items: center;
}

.zrlintergrallable {
  font-size: 28px;
  font-family: PingFang;
  font-weight: 500;
  color: #666666;
  text-align: center;
}

.zrlintergralnum {
  font-size: 28px;
  font-family: Heiti SC;
  font-weight: 500;
  color: #333333;
  margin-top: 30px;
  text-align: center;
}

.zrlmargintop20 {
  margin-top: 20px;
}

.zrlflexboxtwo {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.zrlmingxititle {
  font-size: 30px;
  font-family: PingFang;
  font-weight: bold;
  color: #333333;
}

.zrlmonthinput {
  width: 190px;
  height: 50px;
  background: #ffffff;
  border: 1px solid #e3e3e3;
  padding-left: 30px;
  line-height: 50px;
  position: relative;
  font-size: 28px;
  font-family: PingFang;
  font-weight: 500;
  color: #666666;
}

.zrlxialaicon {
  width: 20px;
  height: 14px;
  position: absolute;
  top: 18px;
  right: 30px;
}

.zrlmargintop40 {
  margin-top: 40px;
}

.zrljifenicon {
  width: 29px;
  height: 29px;
  margin-right: 8px;
}

.zrlinlineblock {
  display: inline-block;
  vertical-align: top;
}

.zrljinfenname {
  font-size: 24px;
  font-family: PingFang;
  font-weight: 500;
  color: #333333;
}

.zrljifentime {
  font-size: 22px;
  font-family: PingFang;
  font-weight: 500;
  color: #8c94a1;
}

.zrljifennum {
  font-size: 24px;
  font-family: Heiti SC;
  font-weight: 500;
  color: #010101;
  text-align: right;
}

@media screen and (min-width: 1000px) {
  .lg {
    display: none;
  }

  .zrlcontent {
    display: block;
  }
}
</style>
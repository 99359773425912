<template>
  <div>
    <div class="lg aboutus">
        <img class="wid750" src="../../assets/imgs/aboutus.jpg" alt="">
    </div>
    <div class="zrl zrlaboutus">
        <img class="zrlwid750" src="../../assets/imgs/aboutus.jpg" alt="">
    </div>
  </div>
</template>

<script>
export default {

}
</script>

<style lang="less" scoped>
.wid750{
    width: 750px;
}
.zrlwid750{
    width: 750px;
   
}
.zrlaboutus{
  display: none;
  width: 750px;
  margin: 0 auto;
}
@media screen and (min-width: 1000px) {
  .lg{
    display: none;
  }
  .zrlaboutus{
    display: block;
  }
}

</style>
<template>
  <div>
    <!-- <Logodia></Logodia> -->
    <!-- <div class="zhanwei"></div> -->

    <div class="lg">
      <div class="mainbox">
        <div class="maintitle">
          购物车(<span class="color986">{{ allnum }}</span>)
        </div>
        <div class="mainitembox" v-for="item in cartList" :key="item.id">
          <div class="checkcionbox" @click="changeChecked(item)">
            <img v-if="item.isCkecked == true" class="ckeckicon" src="../../assets/imgs/xuanzhong@3x.png" alt="" />
          </div>
          <div>
            <img @click="toCommoditydetails(item)" class="mainimg" :src="item.productMainImage" alt="" />
          </div>
          <div class="mainiteninfobox">
            <div class="flexboxtwo">
              <div class="infonamebox" @click="toCommoditydetails(item)">{{ item.productName }}</div>
              <div class="infotitlebox">{{ item.productBrief }}</div>
              <div class="skuinfo">
                <div class="skuinfoitem" v-for="(ctem, index) in item.skuVal" :key="index">
                  {{ ctem }}
                </div>
              </div>
              <div class="flexbox">
                <div class="skuinfoprice">￥{{ item.unitPrice }}</div>
                <div>
                  <van-stepper :value="item.num" disable-input async-change @change="onChange($event, item)" min="1"
                    max="10" />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="zhanwei"></div>
      <div class="footbox">
        <div class="footmainbox">
          <div>
            <div class="checkcionbox inlineblock" @click="changeAllCheck">
              <img v-if="allChecked" class="ckeckicon" src="../../assets/imgs/xuanzhong@3x.png" alt="" />
            </div>
            <div class="inlineblock quanxuanicon">全选</div>
          </div>
          <div class="deltext" @click="delCart">删除</div>
          <div>
            <span class="zhifulable">合计支付：</span>
            <span class="pricefont">￥{{ allPrice }}</span>
          </div>
          <div class="buybtn" @click="toBuyfromCart">结算({{ allnumber }})</div>
        </div>
      </div>
    </div>
    <div class="zrl zrlbox">
      <div class="zrlmainbox">
        <div class="zrlmaintitle">
          购物车(<span class="zrlcolor986">{{ allnum }}</span>)
        </div>
        <div class="zrlmainitembox" v-for="item in cartList" :key="item.id">
          <div class="zrlcheckcionbox" @click="changeChecked(item)">
            <img v-if="item.isCkecked == true" class="zrlckeckicon" src="../../assets/imgs/xuanzhong@3x.png" alt="" />
          </div>
          <div>
            <img @click="toCommoditydetails(item)" class="zrlmainimg" :src="item.productMainImage" alt="" />
          </div>
          <div class="zrlmainiteninfobox">
            <div class="zrlflexboxtwo">
              <div class="zrlinfonamebox" @click="toCommoditydetails(item)">{{ item.productName }}</div>
              <div class="zrlinfotitlebox">{{ item.productBrief }}</div>
              <div class="zrlskuinfo">
                <div class="zrlskuinfoitem" v-for="(ctem, index) in item.skuVal" :key="index">
                  {{ ctem }}
                </div>
              </div>
              <div class="zrlflexbox">
                <div class="zrlskuinfoprice">￥{{ item.unitPrice }}</div>
                <div>
                  <van-stepper :value="item.num" disable-input async-change @change="onChange($event, item)" min="1"
                    max="10" />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="zrlzhanwei"></div>
      <div class="zrlfootbox">
        <div class="zrlfootmainbox">
          <div>
            <div class="zrlcheckcionbox zrlinlineblock" @click="changeAllCheck">
              <img v-if="allChecked" class="zrlckeckicon" src="../../assets/imgs/xuanzhong@3x.png" alt="" />
            </div>
            <div class="zrlinlineblock zrlquanxuanicon">全选</div>
          </div>
          <div class="zrldeltext" @click="delCart">删除</div>
          <div>
            <span class="zrlzhifulable">合计支付：</span>
            <span class="zrlpricefont">￥{{ allPrice }}</span>
          </div>
          <div class="zrlbuybtn" @click="toBuyfromCart">结算({{ allnumber }})</div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { cartList_api, upCartNum_api, delCart_api } from "../../apis/cart";
import { buyInfoTwo_api } from "../../apis/order";
import Logodia from "../../components/Logodia.vue";
export default {
  components: {
    Logodia,
  },
  data() {
    return {
      value: 1,
      searchData: {
        pageNumber: 1,
        pageSize: 999,
      },
      cartList: [],
      allnum: 0,
      allChecked: false,
      allPrice: 0,
      allnumber: 0,
    };
  },
  created() {
    this.getCartList();
  },
  methods: {
    async getCartList() {
      let { code, data } = await cartList_api(this.searchData);
      if (code == 0) {
        this.cartList = data.rows;
        this.allnum = data.total;
        this.cartList.forEach((item) => {
          this.$set(item, "isCkecked", false);
        });
      }
    },
    async toBuyfromCart() {
      if (this.allnumber == 0) {
        this.$toast("请选择商品");
      } else {
        let idsList = [];
        this.cartList.forEach((item) => {
          if (item.isCkecked == true) {
            idsList.push(item.id);
          }
        });
        let ids = idsList.join(",");

        let { code, data } = await buyInfoTwo_api({
          spids: ids,
        });
        if (code == 0) {
          this.$router.push(
            {
              path: "/buyfromcart",
              query: {
                type: "cart",
                spids: ids,
              },
            },
            () => { }
          );
        }
      }
    },
    delCart() {
      if (this.allnumber == 0) {
        this.$toast("请选择商品");
      } else {
        this.$dialog
          .confirm({
            message: "确定要删除吗？",
          })
          .then(() => {
            this.delCartFn();
          });
      }
    },
    async delCartFn() {
      let idsList = [];
      this.cartList.forEach((item) => {
        if (item.isCkecked == true) {
          idsList.push(item.id);
        }
      });
      let ids = idsList.join(",");
      let { code, data } = await delCart_api({
        id: ids,
      });
      if (code == 0) {
        this.$toast.success("已删除");
        this.getCartList();
        this.getAllPrice();
      }
    },
    changeChecked(item) {
      console.log(item);
      item.isCkecked = !item.isCkecked;
      this.getAllPrice();
    },
    changeAllCheck() {
      this.allChecked = !this.allChecked;
      if (this.allChecked) {
        this.cartList.forEach((item) => {
          item.isCkecked = true;
        });
      } else {
        this.cartList.forEach((item) => {
          item.isCkecked = false;
        });
      }
      this.getAllPrice();
    },
    async onChange(val, item) {
      let { code, data } = await upCartNum_api({
        id: item.id,
        num: val,
      });
      if (code == 0) {
        item.num = val;
      } else {
        this.$toast("超出购买限制");
      }
      this.getAllPrice();
    },
    getAllPrice() {
      this.allPrice = 0;
      this.allnumber = 0;
      this.cartList.forEach((item) => {
        if (item.isCkecked) {
          this.allnumber += item.num;
          this.allPrice += item.num * item.unitPrice;
        }
      });
    },
    toCommoditydetails(item) {
      this.$router.push(
        {
          path: "/commoditydetails",
          query: {
            commodityId: item.productId
          }
        },
        () => { }
      );
    }
  },
};
</script>

<style lang="less" scoped>
.zhanwei {
  width: 100%;
  height: 150px;
}

.logobox {
  width: 130px;
  height: 48px;
  margin: 0 auto;
}

.logoimg {
  width: 130px;
  height: 48px;
}

.categorybox {
  display: flex;
  flex-wrap: wrap;
  border-top: 1px solid #dddddd;
  margin-top: 30px;
}

.categoryitembox {
  width: 148px;
  height: 60px;
  // border-top: 1px solid #dddddd;
  border-right: 1px solid #dddddd;
  border-bottom: 1px solid #dddddd;
  font-size: 24px;
  font-family: PingFang SC;
  font-weight: 500;
  color: #333333;
  line-height: 60px;
  text-align: center;
}

.categoryitembox:nth-child(5n) {
  border-right: none;
}

.mainbox {
  margin: 0 auto;
  width: 690px;
  margin-top: 37px;
}

.maintitle {
  margin-bottom: 48px;
  font-size: 30px;
  font-family: PingFang;
  font-weight: bold;
  color: #333333;
}

.color986 {
  color: #986565;
}

.mainitembox {
  width: 648px;
  padding: 30px 20px;
  // background: #ffffff;
  // border: 1px solid #dddddd;
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 20px;
  border-top: 2px solid #222;
}

.checkcionbox {
  width: 32px;
  height: 32px;
  background: #dddddd;
  border-radius: 2px;
}

.ckeckicon {
  width: 32px;
  height: 32px;
}

.mainimg {
  width: 180px;
  height: 180px;
  border-radius: 5px;
  object-fit: cover;
}

.mainiteninfobox {
  width: 387px;
  min-height: 180px;
}

.infonamebox {
  font-size: 24px;
  font-family: PingFang;
  font-weight: bold;
  color: #666666;
}

.infotitlebox {
  font-size: 24px;
  font-family: PingFang;
  font-weight: 500;
  color: #000000;
}

.skuinfo {
  font-size: 24px;
  font-family: PingFang;
  font-weight: 500;
  color: #999999;
}

.skuinfoitem {
  display: inline-block;
  vertical-align: top;
  margin-right: 10px;
}

.flexbox {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.skuinfoprice {
  font-size: 30px;
  font-family: PingFang;
  font-weight: 500;
  color: #000000;
}

.flexboxtwo {
  min-height: 180px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.footbox {
  width: 750px;
  height: 120px;
  background: #ffffff;
  box-shadow: 0px -1px 7px 0px rgba(235, 235, 236, 0.42);
  position: fixed;
  left: 0px;
  bottom: 0px;
  padding-top: 30px;
}

.footmainbox {
  margin: 0px auto;
  width: 690px;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.inlineblock {
  display: inline-block;
  vertical-align: top;
}

.quanxuanicon {
  font-size: 24px;
  font-family: PingFang;
  font-weight: bold;
  color: #333333;
  margin-left: 20px;
}

.deltext {
  font-size: 24px;
  font-family: PingFang;
  font-weight: bold;
  color: #f56c6c;
  margin-left: 20px;
}

.zhifulable {
  font-size: 24px;
  font-family: PingFang;
  font-weight: 500;
  color: #000000;
  // margin-left: 50px;
  line-height: 70px;
}

.pricefont {
  font-size: 36px;
  font-family: PingFang;
  font-weight: bold;
  color: #000000;
  margin-left: 10px;
  position: relative;
  top: 3px;
}

.buybtn {
  width: 160px;
  height: 70px;
  background: #986665;
  line-height: 70px;
  text-align: center;
  font-size: 24px;
  font-family: PingFang;
  font-weight: 500;
  color: #ffffff;
}
//pc
.zrlzhanwei {
  width: 100%;
  height: 150px;
}

.zrllogobox {
  width: 130px;
  height: 48px;
  margin: 0 auto;
}

.zrllogoimg {
  width: 130px;
  height: 48px;
}

.zrlcategorybox {
  display: flex;
  flex-wrap: wrap;
  border-top: 1px solid #dddddd;
  margin-top: 30px;
}

.zrlcategoryitembox {
  width: 148px;
  height: 60px;
  // border-top: 1px solid #dddddd;
  border-right: 1px solid #dddddd;
  border-bottom: 1px solid #dddddd;
  font-size: 24px;
  font-family: PingFang SC;
  font-weight: 500;
  color: #333333;
  line-height: 60px;
  text-align: center;
}

.zrlcategoryitembox:nth-child(5n) {
  border-right: none;
}

.zrlmainbox {
  margin: 0 auto;
  width: 690px;
  margin-top: 37px;
}

.zrlmaintitle {
  margin-bottom: 48px;
  font-size: 18px;
  font-family: PingFang;
  font-weight: bold;
  color: #333333;
}

.zrlcolor986 {
  color: #986565;
}

.zrlmainitembox {
  width: 648px;
  padding: 30px 20px;
  // background: #ffffff;
  // border: 1px solid #dddddd;
  display: flex;
  justify-content: space-between;
  align-items: center;
  // margin-bottom: 20px;
  border-top: 2px solid #222;
}

.zrlcheckcionbox {
  width: 32px;
  height: 32px;
  background: #dddddd;
  border-radius: 2px;
}

.zrlckeckicon {
  width: 32px;
  height: 32px;
}

.zrlmainimg {
  width: 90px;
  height: 90px;
  border-radius: 5px;
  object-fit: cover;
}

.zrlmainiteninfobox {
  width: 387px;
  min-height: 90px;
}

.zrlinfonamebox {
  font-size: 16px;
  font-family: PingFang;
  font-weight: bold;
  color: #666666;
}

.zrlinfotitlebox {
  font-size: 16px;
  font-family: PingFang;
  font-weight: 500;
  color: #000000;
}

.zrlskuinfo {
  font-size: 14px;
  font-family: PingFang;
  font-weight: 500;
  color: #999999;
}

.zrlskuinfoitem {
  display: inline-block;
  vertical-align: top;
  margin-right: 10px;
}

.zrlflexbox {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.zrlskuinfoprice {
  font-size: 14px;
  font-family: PingFang;
  font-weight: 500;
  color: #000000;
}

.zrlflexboxtwo {
  min-height: 90px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.zrlfootbox {
  width: 750px;
  height: 60px;
  background: #ffffff;
  box-shadow: 0px -1px 7px 0px rgba(235, 235, 236, 0.42);
  position: fixed;
  left: 50%;
  transform: translateX(-50%);
  bottom: 0px;
  padding-top: 10px;
}

.zrlfootmainbox {
  margin: 0px auto;
  width: 690px;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.zrlinlineblock {
  display: inline-block;
  vertical-align: top;
}

.zrlquanxuanicon {
  font-size: 20px;
  font-family: PingFang;
  font-weight: bold;
  color: #333333;
  margin-left: 20px;
}

.zrldeltext {
  font-size: 20px;
  font-family: PingFang;
  font-weight: bold;
  color: #f56c6c;
  margin-left: 20px;
}

.zrlzhifulable {
  font-size: 20px;
  font-family: PingFang;
  font-weight: 500;
  color: #000000;
  // margin-left: 50px;
  line-height: 50px;
}

.zrlpricefont {
  font-size: 30px;
  font-family: PingFang;
  font-weight: bold;
  color: #000000;
  margin-left: 10px;
  position: relative;
  top: 3px;
}

.zrlbuybtn {
  width: 160px;
  height: 50px;
  background: #986665;
  line-height: 50px;
  text-align: center;
  font-size: 20px;
  font-family: PingFang;
  font-weight: 500;
  color: #ffffff;
}
.zrlbox{
    display: none;
  }
@media screen and (min-width: 1000px) {
  .lg{
    display: none;
  }
  .zrlbox{
    display: block;
  }
}
</style>
<template>
  <div>
    <!-- <Logodia></Logodia> -->
    <!-- <div class="zhanweiforminehead"></div> -->
    <div class="lg content">
      <div class="mainbox">
        <div class="mineheadbox">我的订单</div>
        <div>
          <div class="orderstatebox wid140" @click="toOrderList(0)">
            <div>待付款</div>
            <div class="orderstatenum">{{ orderNumInfo.obligation }}</div>
          </div>
          <div class="orderstatebox wid160" @click="toOrderList(1)">
            <div>待发货</div>
            <div class="orderstatenum">{{ orderNumInfo.tobeshipped }}</div>
          </div>
          <div class="orderstatebox wid160" @click="toOrderList(2)">
            <div>待收货</div>
            <div class="orderstatenum">{{ orderNumInfo.tobereceived }}</div>
          </div>
          <div class="orderstatebox widlast" @click="toOrderList(4)">
            <div>退换/售后</div>
            <div class="orderstatenum">{{ orderNumInfo.sales }}</div>
          </div>
        </div>
      </div>
      <div class="margintop20">
        <van-cell-group>
          <van-cell is-link size="large" @click="toVipPage">
            <template #title>
              <span class="custom-title">会员</span>
            </template>
          </van-cell>
          <van-cell is-link size="large" @click="toMyintergral">
            <template #title>
              <span class="custom-title">积分</span>
            </template>
          </van-cell>
          <van-cell is-link size="large" @click="toFavorites">
            <template #title>
              <span class="custom-title">心愿单</span>
            </template>
          </van-cell>
          <van-cell is-link size="large" @click="toCoupon">
            <template #title>
              <span class="custom-title">优惠券</span>
            </template>
          </van-cell>
          <van-cell is-link size="large" @click="toAddresslist">
            <template #title>
              <span class="custom-title">地址管理</span>
            </template>
          </van-cell>
        </van-cell-group>
      </div>
    </div>
    <div class="zrl zrlcontent">
      <div class="zrlmainbox">
        <div class="zrlmineheadbox" @click="toOrderList('')">我的订单</div>
        <div>
          <div class="zrlorderstatebox zrlwid140" @click="toOrderList(0)">
            <div>待付款</div>
            <div class="zrlorderstatenum">{{ orderNumInfo.obligation }}</div>
          </div>
          <div class="zrlorderstatebox zrlwid160" @click="toOrderList(1)">
            <div>待发货</div>
            <div class="zrlorderstatenum">{{ orderNumInfo.tobeshipped }}</div>
          </div>
          <div class="zrlorderstatebox zrlwid160" @click="toOrderList(2)">
            <div>待收货</div>
            <div class="zrlorderstatenum">{{ orderNumInfo.tobereceived }}</div>
          </div>
          <div class="zrlorderstatebox zrlwidlast" @click="toOrderList(4)">
            <div>退换/售后</div>
            <div class="zrlorderstatenum">{{ orderNumInfo.sales }}</div>
          </div>
        </div>
      </div>
      <div class="zrlmargintop20">
        <van-cell-group class="zrlplan">
          <van-cell is-link size="large" class="zrlplan"  @click="toVipPage">
            <template #title>
              <span class="zrlcustom-title">会员</span>
            </template>
          </van-cell>
          <van-cell is-link size="large" class="zrlplan" @click="toMyintergral">
            <template #title>
              <span class="zrlcustom-title">积分</span>
            </template>
          </van-cell>
          <van-cell is-link size="large" class="zrlplan" @click="toFavorites">
            <template #title>
              <span class="zrlcustom-title">心愿单</span>
            </template>
          </van-cell>
          <van-cell is-link size="large" class="zrlplan" @click="toCoupon">
            <template #title>
              <span class="zrlcustom-title">优惠券</span>
            </template>
          </van-cell>
          <van-cell is-link size="large" class="zrlplan" @click="toAddresslist">
            <template #title>
              <span class="zrlcustom-title">地址管理</span>
            </template>
          </van-cell>
        </van-cell-group>
      </div>
    </div>
  </div>
</template>

<script>
import local from "@/utils/local.js";
import session from "@/utils/session";
import {getOrderNum_api} from '../../apis/order'
import Logodia from "../../components/Logodia.vue"
export default {
  components:{
    Logodia
  },
  data() {
    return {
      isLogin: false,
      orderNumInfo:{
        obligation:0,
        sales:0,
        tobereceived:0,
        tobeshipped:0,
      },
    };
  },
  created() {
    if (local.get("tk") || session.get("tk")) {
      this.isLogin = true;
    } else {
      this.isLogin = false;
    }
    this.getOrderNum()
  },
  methods: {
    toVipPage(val) {
      if (this.isLogin) {
        this.$router.push(
          {
            path: "/vippage",
            query: {
              searchValue: val,
            },
          },
          () => {}
        );
      } else {
        this.$dialog.alert({
          message: "你还不是会员",
        });
      }
    },
    toMyintergral(val){
        this.$router.push(
          {
            path: "/myintegral",
            query: {
              searchValue: val,
            },
          },
          () => {}
        );

    },
    toFavorites(val){
        this.$router.push(
          {
            path: "/favorites",
            query: {
              searchValue: val,
            },
          },
          () => {}
        );
    },
    toCoupon(val){
        this.$router.push(
          {
            path: "/coupon",
            query: {
              searchValue: val,
            },
          },
          () => {}
        );
    },
    toAddresslist(val){
        this.$router.push(
          {
            path: "/addresslist",
            query: {
              searchValue: val,
            },
          },
          () => {}
        );
    },
   async getOrderNum(){
      let {code,data}=await getOrderNum_api()
      if(code==0){
        this.orderNumInfo=data
      }
    },
    toOrderList(type){
      this.$router.push(
          {
            path: "/orderlist",
            query: {
              type: type,
            },
          },
          () => {}
        );
    }

  },
};
</script>

<style lang="less" scoped>
.zhanweiforminehead {
  width: 100%;
  height: 50px;
}
.content {
  margin: 0 auto;
  width: 690px;
}
.mainbox {
  padding: 30px 20px 80px 20px;
  width: 650px;
  background: #ffffff;
  border: 1px solid #dddddd;
}
.mineheadbox {
  font-size: 30px;
  font-family: PingFang;
  font-weight: bold;
  color: #333333;
  padding-bottom: 30px;
  border-bottom: 1px solid #e8e8e8;
  margin-bottom: 50px;
}
.orderstatebox {
  display: inline-block;
  vertical-align: top;
  height: 80px;
  border-right: 1px solid #e8e8e8;
  text-align: center;

  font-size: 28px;
  font-family: PingFang;
  font-weight: bold;
  color: #666666;
}
.wid140 {
  width: 140px;
}
.wid160 {
  width: 160px;
}
.widlast {
  border-right: none;
  width: 180px;
}
.orderstatenum {
  font-size: 28px;
  font-family: Heiti SC;
  font-weight: 500;
  color: #333333;
  margin-top: 10px;
}
.custom-title {
  font-size: 26px;
  font-family: PingFang;
  font-weight: bold;
  color: #333333;
}
.margintop20 {
  margin-top: 20px;
}


.zrlzhanweiforminehead {
  width: 100%;
  height: 50px;
}
.zrlcontent {
  margin: 0 auto;
  width: 690px;
  display: none;
}
.zrlmainbox {
  padding: 30px 20px 80px 20px;
  width: 650px;
  // background: #ffffff;
  // border: 1px solid #dddddd;
}
.zrlmineheadbox {
  font-size: 30px;
  font-family: PingFang;
  font-weight: bold;
  color: #333333;
  padding-bottom: 30px;
  // border-bottom: 1px solid #e8e8e8;
  margin-bottom: 50px;
  cursor: pointer;
}
.zrlorderstatebox {
  display: inline-block;
  vertical-align: top;
  height: 80px;
  border: 2px solid #666666;
  text-align: center;
margin-right: 5px;
  font-size: 20px;
  font-family: PingFang;
  font-weight: bold;
  color: #666666;
  cursor: pointer;
}
.zrlwid140 {
  width: 120px;
}
.zrlwid160 {
  width: 140px;
}
.zrlwidlast {
  // border-right: none;
  width: 160px;
}
.zrlorderstatenum {
  font-size: 28px;
  font-family: Heiti SC;
  font-weight: 500;
  color: #333333;
  margin-top: 10px;
}
.zrlcustom-title {
  font-size: 26px;
  font-family: PingFang;
  font-weight: bold;
  color: #333333;
}
.zrlmargintop20 {
  margin-top: 20px;
}
.zrlplan{
  background: #eeeee2!important;
  border-bottom: 1px solid #666666;
}
@media screen and (min-width: 1000px) {
  .lg{
    display: none;
  }
  .zrlcontent{
    display: block;
  }
}
</style>
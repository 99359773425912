<template>
  <div>
    <!-- <Categorylist></Categorylist> -->
    <div class="lg">
      <div class="mainbox">
        <div class="maintitle">
          {{ bulletinBoardInfo.title }}
        </div>
        <div class="mainlable">
          <span>{{ bulletinBoardInfo.author || "seooocookie" }}</span>
          <span class="marginleft20">{{ bulletinBoardInfo.createTime }}</span>
          <span class="marginleft20" v-if="bulletinBoardInfo.scanisshow==1"
            >查看次数{{ bulletinBoardInfo.scan + bulletinBoardInfo.scanback }}次</span
          >
        </div>
        <div class="maincontent">
          <div v-html="setImgWidth(bulletinBoardInfo.content)"></div>
        </div>
      </div>
    </div>
    <div class="zrl zrlcontent">
      <div class="zrlmainbox">
        <div class="zrlmaintitle">
          {{ bulletinBoardInfo.title }}
        </div>
        <div class="zrlmainlable">
          <span>{{ bulletinBoardInfo.author || "seooocookie" }}</span>
          <span class="zrlmarginleft20">{{ bulletinBoardInfo.createTime }}</span>
          <span class="zrlmarginleft20" v-if="bulletinBoardInfo.scanisshow==1"
            >查看次数{{ bulletinBoardInfo.scan + bulletinBoardInfo.scanback }}次</span
          >
        </div>
        <div class="zrlmaincontent">
          <div v-html="setImgWidth(bulletinBoardInfo.content)"></div>
        </div>
      </div>
    </div>
  </div>
</template>
  
  <script>
import Categorylist from "../../components/Categorylist.vue";
import { bulletinBoardInfo_api } from "../../apis/bulletinboard";
import local from "@/utils/local.js";
export default {
  components: {
    Categorylist,
  },
  data() {
    return {
      bulletinBoardInfo: {
        title: "",
        author: "",
        content: "",
        isactive: "",
        scan: 0,
        sortnumber: 1,
        title: "",
        isindex:0,
        scanback:0,
        scanisshow:0,
      },

      bulletinBoardId: "",
      localUserInfo:{
        id:null
      }
    };
  },
  created() {
    if (local.get("userInfo")) {
      this.localUserInfo = JSON.parse(local.get("userInfo"))
    }
    this.bulletinBoardId = this.$route.query.id;
    this.getBulletinBoardInfo();
  },
  methods: {
    async getBulletinBoardInfo() {
      let { code, data } = await bulletinBoardInfo_api({
        id: this.bulletinBoardId,
        userid: this.localUserInfo.id || '',
      });
      if (code == 0) {
        this.bulletinBoardInfo = data;
      }
    },
    setImgWidth(htmlContent) {
      return htmlContent.replace(/<img/gi, '<img width="100%"');
    }
  },
};
</script>
  
  <style lang="less" scoped>
.mainbox {
  margin: 0 auto;
  margin-top: 58px;
  width: 690px;
  text-align: center;
}
.maintitle {
  font-size: 28px;
  font-family: PingFang;
  font-weight: 500;
  color: #333333;
}
.mainlable {
  margin-top: 20px;
  font-size: 24px;
  font-family: PingFang;
  font-weight: 500;
  color: #999999;
}
.marginleft20 {
  margin-left: 20px;
}
.maincontent {
  margin-top: 58px;
  padding-bottom: 100px;
  text-align: left;
}
//pc
.zrlcontent{
  display: none;
}
.zrlmainbox {
  margin: 0 auto;
  margin-top: 58px;
  width: 690px;
  text-align: center;
}
.zrlmaintitle {
  font-size: 16px;
  font-family: PingFang;
  font-weight: 500;
  color: #333333;
}
.zrlmainlable {
  margin-top: 20px;
  font-size: 14px;
  font-family: PingFang;
  font-weight: 500;
  color: #999999;
}
.zrlmarginleft20 {
  margin-left: 20px;
}
.zrlmaincontent {
  margin-top: 58px;
  padding-bottom: 100px;
  text-align: left;
}

@media screen and (min-width: 1000px) {
  .lg{
    display: none;
  }
  .zrlcontent{
    display: block;
  }
}
</style>
<template>
  <div>
    <!-- <Logodia></Logodia> -->
    <div class="lg">
      <div class="content">
        <!-- <div class="zhanweifroorder"></div> -->
        <div class="mainbox">
          <div v-if="orderInfo.status == 0">
            <div class="statefont">处理中</div>
            <div class="statelable">正在处理退款，请耐心等待</div>
          </div>
          <div v-if="orderInfo.status == 1">
            <div class="statefont">商家已同意退款</div>
            <div class="statelable" v-if="orderInfo.courierName && orderInfo.courierNo">
              货款将在1~3个工作日返回你的账户
            </div>
            <div class="statelable" v-if="!orderInfo.courierName || !orderInfo.courierNo">
              请填写物流单号，完成退款
            </div>
          </div>
          <div v-if="orderInfo.status == 2">
            <div class="statefont">退款失败</div>
            <div class="statelable">
              商家已拒绝您的退款申请，如有疑问请联系客服
              {{ orderInfo.rejectReason }}
            </div>
          </div>
          <div v-if="orderInfo.status == 3">
            <div class="statefont">已取消</div>
            <div class="statelable">已取消退款申请</div>
          </div>
        </div>
        <div class="mainbox" v-if="orderInfo.status == 1 && orderInfo.afterSaleType == 2">
          <div class="courierlable">物流单号</div>
          <div>
            <input class="couriernoinput" type="text" v-model="courierInfo.courierNo" />
          </div>
          <div class="courierlable margintop10">物流公司</div>
          <div>
            <input class="couriernoinput" type="text" v-model="courierInfo.courierName" />
          </div>
        </div>
        <div class="mainbox">
          <div class="flexbox marginbottom60">
            <div class="titlelable">商品信息</div>
            <div class="titleval">共{{ orderInfo.num }}件</div>
          </div>

          <div v-for="item in orderInfo.productList" :key="item.productId">
            <div class="mainitembox">
              <div>
                <img class="mainimg" :src="item.productMainImage" alt="" />
              </div>
              <div class="mainiteninfobox">
                <div class="flexboxtwo">
                  <div class="infonamebox">{{ item.productName }}</div>
                  <div class="infotitlebox"></div>
                  <div class="skuinfo" v-if="item.productSkuName">
                    <div class="skuinfoitem" v-for="(ctem, index) in item.productSkuName" :key="index">
                      {{ ctem }}
                    </div>
                  </div>
                  <div class="flexbox">
                    <div class="skuinfoprice">￥{{ item.unitPrice }}</div>
                    <div class="skunum">x{{ item.num }}</div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="margintop41">
            <van-divider :style="{ color: '#c4c4c4' }"></van-divider>
          </div>
          <div class="allamountbox">
            共{{ orderInfo.num }}件
            <span class="marginleft10">退款金额：</span>
            <span class="allamountnum">￥{{ orderInfo.amount }}</span>
          </div>
        </div>
        <div class="mainbox pad50">
          <div class="maintitle">退款信息</div>
          <div class="flexbox minhei50">
            <div>退款类型*</div>
            <div class="descriptionbox" v-if="orderInfo.afterSaleType == 2">
              退货退款
            </div>
            <div class="descriptionbox" v-if="orderInfo.afterSaleType == 1">
              仅退款
            </div>
          </div>
          <div class="flexbox minhei50">
            <div>申请时间</div>
            <div class="descriptionbox">
              {{ orderInfo.application }}
            </div>
          </div>
          <div class="flexbox minhei50">
            <div>退货单号</div>
            <div class="descriptionbox">
              {{ orderInfo.saleNo }}
            </div>
          </div>
          <div class="flexbox minhei50">
            <div>退款金额</div>
            <div class="descriptionbox">￥ {{ orderInfo.amount || 0 }}</div>
          </div>
          <div class="flexbox minhei50">
            <div>退款原因</div>
            <div class="descriptionbox">{{ orderInfo.description }}</div>
          </div>
          <div class="flexbox minhei50" v-if="orderInfo.imgUrl">
            <div>补充图片</div>
            <div class="descriptionbox">
              <van-image v-for="(item, index) in images" :key="index" class="imgbox" :src="item"
                @click="openPreview(index)" />
            </div>
          </div>
        </div>
      </div>
      <div class="footzhanwei"></div>
      <div class="footbox" v-if="orderInfo.status == 0">
        <div class="footmainbox flexbox">
          <div class="cancelbtn" @click="cancelSaledOrder">撤销申请</div>
        </div>
      </div>
      <div class="footbox" v-if="
        orderInfo.status == 1 &&
        orderInfo.afterSaleType ==
        2(!orderInfo.courierName || !orderInfo.courierNo)
      ">
        <div class="setcourierbtn" @click="addWl">提交</div>
      </div>
      <van-image-preview v-model="showPreview" :images="images" @change="onChange">
        <template v-slot:index>第{{ index + 1 }}张</template>
      </van-image-preview>
    </div>
    <div class="zrl zrlbox">
      <div class="zrlcontent">
        <!-- <div class="zhanweifroorder"></div> -->
        <div class="zrlmainbox">
          <div v-if="orderInfo.status == 0">
            <div class="zrlstatefont">处理中</div>
            <div class="zrlstatelable">正在处理退款，请耐心等待</div>
          </div>
          <div v-if="orderInfo.status == 1">
            <div class="zrlstatefont">商家已同意退款</div>
            <div class="zrlstatelable" v-if="orderInfo.courierName && orderInfo.courierNo">
              货款将在1~3个工作日返回你的账户
            </div>
            <div class="zrlstatelable" v-if="!orderInfo.courierName || !orderInfo.courierNo">
              请填写物流单号，完成退款
            </div>
          </div>
          <div v-if="orderInfo.status == 2">
            <div class="zrlstatefont">退款失败</div>
            <div class="zrlstatelable">
              商家已拒绝您的退款申请，如有疑问请联系客服
              {{ orderInfo.rejectReason }}
            </div>
          </div>
          <div v-if="orderInfo.status == 3">
            <div class="zrlstatefont">已取消</div>
            <div class="zrlstatelable">已取消退款申请</div>
          </div>
        </div>
        <div class="zrlmainbox" v-if="orderInfo.status == 1 && orderInfo.afterSaleType == 2">
          <div class="zrlcourierlable">物流单号</div>
          <div>
            <input class="zrlcouriernoinput" type="text" v-model="courierInfo.courierNo" />
          </div>
          <div class="zrlcourierlable zrlmargintop10">物流公司</div>
          <div>
            <input class="zrlcouriernoinput" type="text" v-model="courierInfo.courierName" />
          </div>
        </div>
        <div class="zrlmainbox">
          <div class="zrlflexbox zrlmarginbottom60">
            <div class="zrltitlelable">商品信息</div>
            <div class="zrltitleval">共{{ orderInfo.num }}件</div>
          </div>

          <div v-for="item in orderInfo.productList" :key="item.productId">
            <div class="zrlmainitembox">
              <div>
                <img class="zrlmainimg" :src="item.productMainImage" alt="" />
              </div>
              <div class="zrlmainiteninfobox">
                <div class="zrlflexboxtwo">
                  <div class="zrlinfonamebox">{{ item.productName }}</div>
                  <div class="zrlinfotitlebox"></div>
                  <div class="zrlskuinfo" v-if="item.productSkuName">
                    <div class="zrlskuinfoitem" v-for="(ctem, index) in item.productSkuName" :key="index">
                      {{ ctem }}
                    </div>
                  </div>
                  <div class="zrlflexbox">
                    <div class="zrlskuinfoprice">￥{{ item.unitPrice }}</div>
                    <div class="zrlskunum">x{{ item.num }}</div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="zrlmargintop41">
            <van-divider :style="{ color: '#c4c4c4' }"></van-divider>
          </div>
          <div class="zrlallamountbox">
            共{{ orderInfo.num }}件
            <span class="zrlmarginleft10">退款金额：</span>
            <span class="zrlallamountnum">￥{{ orderInfo.amount }}</span>
          </div>
        </div>
        <div class="zrlmainbox zrlpad50">
          <div class="zrlmaintitle">退款信息</div>
          <div class="zrlflexbox zrlminhei50">
            <div>退款类型*</div>
            <div class="zrldescriptionbox" v-if="orderInfo.afterSaleType == 2">
              退货退款
            </div>
            <div class="zrldescriptionbox" v-if="orderInfo.afterSaleType == 1">
              仅退款
            </div>
          </div>
          <div class="zrlflexbox zrlminhei50">
            <div>申请时间</div>
            <div class="zrldescriptionbox">
              {{ orderInfo.application }}
            </div>
          </div>
          <div class="zrlflexbox zrlminhei50">
            <div>退货单号</div>
            <div class="zrldescriptionbox">
              {{ orderInfo.saleNo }}
            </div>
          </div>
          <div class="zrlflexbox zrlminhei50">
            <div>退款金额</div>
            <div class="zrldescriptionbox">￥ {{ orderInfo.amount || 0 }}</div>
          </div>
          <div class="zrlflexbox zrlminhei50">
            <div>退款原因</div>
            <div class="zrldescriptionbox">{{ orderInfo.description }}</div>
          </div>
          <div class="zrlflexbox zrlminhei50" v-if="orderInfo.imgUrl">
            <div>补充图片</div>
            <div class="zrldescriptionbox">
              <van-image v-for="(item, index) in images" :key="index" class="zrlimgbox" :src="item"
                @click="openPreview(index)" />
            </div>
          </div>
        </div>
      </div>
      <div class="zrlfootzhanwei"></div>
      <div class="zrlfootbox" v-if="orderInfo.status == 0">
        <div class="zrlfootmainbox zrlflexbox">
          <div class="zrlcancelbtn" @click="cancelSaledOrder">撤销申请</div>
        </div>
      </div>
      <div class="zrlfootbox" v-if="
        orderInfo.status == 1 &&
        orderInfo.afterSaleType ==
        2(!orderInfo.courierName || !orderInfo.courierNo)
      ">
        <div class="zrlsetcourierbtn" @click="addWl">提交</div>
      </div>
      <van-image-preview v-model="showPreview" :images="images" @change="onChange">
        <template v-slot:index>第{{ index + 1 }}张</template>
      </van-image-preview>
    </div>
  </div>
</template>

<script>
import {
  saleOrderInfo_api,
  cancelSaledOrder_api,
  addWl_api,
  imOrderInfo_api
} from "../../apis/order";
import Logodia from "../../components/Logodia.vue";
export default {
  components: {
    Logodia,
  },
  data() {
    return {
      orderInfo: {
        afterSaleNo: "",
        candelRemarks: "",
        candelTime: "",
        confirmTime: "",
        couponAmount: 0,
        couponIds: "",
        courierCompany: "",
        courierNumber: "",
        courierRemarks: "",
        deliverAddress: "",
        deliverArea: "",
        deliverCity: "",
        deliverId: "",
        deliverName: "",
        deliverProvince: "",
        deliverTel: "",
        deliverTime: "",
        freightAmount: "",
        integral: "",
        integralAmount: "",
        isAfterSale: 0,
        isCoupon: 0,
        isIntegral: 0,
        isPay: 0,
        num: 0,
        orderNum: "",
        outPayTime: "",
        paidAmount: "",
        payTime: "",
        payType: 1,
        productList: [],
        rentAmount: "",
        status: 0,
        totalAmount: "",
        transactionId: "",
        userId: "",
        courierName: "",
        courierNo: "",
      },
      orderId: "",
      orderType: "",
      payType: "alipay",
      showPayTypePop: false,
      haisiyInfo: "",
      showHaisiyPop: false,
      saleProductList: [],
      showTuihuoPop: false,
      allTuihuoPrice: 0,
      allCheckedTuihuo: false,
      courierInfo: {
        courierName: "",
        courierNo: "",
        id: "",
      },
      showPreview: false,
      index: 0,
      images: [],
    };
  },
  created() {
    this.orderId = this.$route.query.id;
    // this.$bus.$emit('imOrderId',this.orderId)


    this.orderType = this.$route.query.type;
    if (this.orderType == "saleOrder") {
      this.getSaleOrderInfo();
    }
  },
  methods: {
    openPreview(index) {
      this.index = index;
      this.showPreview = true;
    },
    async getSaleOrderInfo() {
      let { code, data } = await saleOrderInfo_api({
        id: this.orderId,
      });
      if (code == 0) {
        this.orderInfo = data;
        this.courierInfo.courierName = this.orderInfo.courierName;
        this.courierInfo.courierNo = this.orderInfo.courierNo;
        this.courierInfo.id = this.orderInfo.id;
        if (this.orderInfo.imgUrl) {
          this.images = this.orderInfo.imgUrl.split(",");
        }

        this.getImOrderInfo()

      }
    },
    cancelSaledOrder() {
      this.$dialog
        .confirm({
          message: "确认撤销退款申请吗？",
        })
        .then(() => {
          this.cancelSaledOrderFn();
        });
    },
    async cancelSaledOrderFn() {
      let { code, data } = await cancelSaledOrder_api({
        id: this.orderInfo.id,
      });
      if (code == 0) {
        this.$toast.success("撤销成功~");
        this.getSaleOrderInfo();
      }
    },
    async addWl() {
      if (!this.courierInfo.courierName || !this.courierInfo.courierNo) {
        this.$toast.fail("请填写物流信息");
      } else {
        let { code, data } = await addWl_api(this.courierInfo);
        if (code == 0) {
          this.$toast.success("物流信息填写成功~");
          this.getSaleOrderInfo();
        }
      }
    },
    onChange(index) {
      this.index = index;
    },
    async getImOrderInfo() {

      let { code, data } = await imOrderInfo_api({
        id: this.orderInfo.orderId,
      });
      if (code == 0) {
        console.log(data);
        if (!data.id) {
          m7ProductInfo.visible = false;
        } else {
          m7ProductInfo.title = data.productList[0].productName;
          m7ProductInfo.img = data.productList[0].productMainImage;
          m7ProductInfo.price = "￥" + data.totalAmount;
          m7ProductInfo.attr_one = {
            color: "#000000",
            content: `X${data.num}`,
          };
          let attrTwo = null;
          if (data.status == 0) {
            attrTwo = "待付款 ";
          } else if (data.status == 1) {
            attrTwo = "待发货 ";
          } else if (data.status == 2) {
            attrTwo = "待收货 ";
          } else if (data.status == 3) {
            attrTwo = "确认收货 ";
          } else if (data.status == 4) {
            attrTwo = "售后 ";
          } else if (data.status == 5) {
            attrTwo = "已取消 ";
          }
          m7ProductInfo.attr_two = { color: "#000000", content: attrTwo };
          m7ProductInfo.other_title_one = `订单号:${data.orderNum}`;
          m7ProductInfo.other_title_two = `下单时间:${data.createTime}`;
          console.log(m7ProductInfo);
        }
      }

    }
  },
};
</script>

<style lang="less" scoped>
.content {
  width: 690px;
  margin: 0 auto;
}

.statefont {
  font-size: 36px;
  font-family: PingFang SC;
  font-weight: bold;
  color: #000000;
  margin-bottom: 20px;
}

.statelable {
  font-size: 28px;
  font-family: PingFang;
  font-weight: 500;
  color: #666666;
}

.zhanweifroorder {
  width: 100%;
  height: 70px;
}

.mainbox {
  width: 630px;
  padding: 40px 30px;
  margin-bottom: 20px;
  background: #fff;
}

.flexbox {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.marginbottom60 {
  margin-bottom: 60px;
}

.titlelable {
  font-size: 24px;
  font-family: PingFang;
  font-weight: bold;
  color: #000000;
}

.titleval {
  font-size: 24px;
  font-family: PingFang;
  font-weight: bold;
  color: #999999;
  letter-spacing: 5px;
}

.mainitembox {
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-wrap: wrap;
  // margin-bottom: 20px;
}

.mainimg {
  width: 180px;
  height: 180px;
  border-radius: 10px;
  object-fit: cover;
}

.mainiteninfobox {
  width: 420px;
  height: 180px;
}

.infonamebox {
  font-size: 24px;
  font-family: PingFang;
  font-weight: bold;
  color: #666666;
}

.infotitlebox {
  font-size: 24px;
  font-family: PingFang;
  font-weight: 500;
  color: #000000;
}

.skuinfo {
  font-size: 24px;
  font-family: PingFang;
  font-weight: 500;
  color: #999999;
}

.skuinfoitem {
  display: inline-block;
  vertical-align: top;
  margin-right: 10px;
}

.skuinfoprice {
  font-size: 30px;
  font-family: PingFang;
  font-weight: bold;
  color: #000000;
}

.flexboxtwo {
  height: 180px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.skunum {
  font-size: 24px;
  font-family: PingFang;
  font-weight: bold;
  color: #000000;
  letter-spacing: 3px;
}

.allamountbox {
  text-align: right;
  font-size: 24px;
  font-family: PingFang;
  font-weight: bold;
  color: #000000;
}

.marginleft10 {
  margin-left: 10px;
}

.allamountnum {
  font-size: 30px;
  font-family: Heiti SC;
  font-weight: 500;
  color: #000000;
}

.pad50 {
  font-size: 24px;
  font-family: PingFang;
  font-weight: bold;
  color: #000000;
  //   line-height: 40px;
}

.maintitle {
  font-size: 30px;
  font-family: PingFang;
  font-weight: bold;
  color: #010101;
  margin-bottom: 10px;
}

.descriptionbox {
  font-size: 24px;
  font-family: PingFang;
  font-weight: 500;
  color: #999999;
  width: 400px;
}

.minhei50 {
  min-height: 50px;
}

.footbox {
  width: 750px;
  padding-top: 30px;
  padding-bottom: 40px;
  background: #ffffff;
  position: fixed;
  left: 0px;
  bottom: 0px;
}

.footmainbox {
  margin: 0 auto;
  width: 690px;
}

.cancelbtn {
  width: 690px;
  height: 80px;
  background: #f9f9f9;
  border: 1px solid #eeeeee;
  text-align: center;
  line-height: 80px;
  font-size: 24px;
  font-family: PingFang;
  font-weight: bold;
  color: #000000;
}

.confirmbtn {
  width: 330px;
  height: 80px;
  background: #986665;
  text-align: center;
  line-height: 80px;
  font-size: 24px;
  font-family: PingFang;
  font-weight: bold;
  color: #ffffff;
}

.courierlable {
  font-size: 24px;
  font-family: PingFang;
  font-weight: 500;
  color: #999999;
}

.couriernoinput {
  width: 630px;
  height: 80px;
  background: #ffffff;
  border: 1px solid #dddddd;
}

.margintop10 {
  margin-top: 10px;
}

.setcourierbtn {
  margin: 0 auto;
  width: 690px;
  height: 80px;
  background: #986665;
  text-align: center;
  line-height: 80px;
  font-size: 24px;
  font-family: PingFang;
  font-weight: bold;
  color: #ffffff;
}

.footzhanwei {
  width: 100%;
  height: 150px;
}

.imgbox {
  width: 50px;
  display: inline-block;
  vertical-align: top;
  margin-right: 5px;
  margin-bottom: 5px;
}
//pc
.zrlcontent {
  width: 690px;
  margin: 0 auto;
}

.zrlstatefont {
  font-size: 36px;
  font-family: PingFang SC;
  font-weight: bold;
  color: #000000;
  margin-bottom: 20px;
}

.zrlstatelable {
  font-size: 28px;
  font-family: PingFang;
  font-weight: 500;
  color: #666666;
}

.zrlzhanweifroorder {
  width: 100%;
  height: 70px;
}

.zrlmainbox {
  width: 630px;
  padding: 40px 30px;
  margin-bottom: 20px;
  // background: #fff;
}

.zrlflexbox {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.zrlmarginbottom60 {
  margin-bottom: 60px;
}

.zrltitlelable {
  font-size: 24px;
  font-family: PingFang;
  font-weight: bold;
  color: #000000;
}

.zrltitleval {
  font-size: 24px;
  font-family: PingFang;
  font-weight: bold;
  color: #999999;
  letter-spacing: 5px;
}

.zrlmainitembox {
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-wrap: wrap;
  // margin-bottom: 20px;
}

.zrlmainimg {
  width: 180px;
  height: 180px;
  border-radius: 10px;
  object-fit: cover;
}

.zrlmainiteninfobox {
  width: 420px;
  height: 180px;
}

.zrlinfonamebox {
  font-size: 24px;
  font-family: PingFang;
  font-weight: bold;
  color: #666666;
}

.zrlinfotitlebox {
  font-size: 24px;
  font-family: PingFang;
  font-weight: 500;
  color: #000000;
}

.zrlskuinfo {
  font-size: 24px;
  font-family: PingFang;
  font-weight: 500;
  color: #999999;
}

.zrlskuinfoitem {
  display: inline-block;
  vertical-align: top;
  margin-right: 10px;
}

.zrlskuinfoprice {
  font-size: 30px;
  font-family: PingFang;
  font-weight: bold;
  color: #000000;
}

.zrlflexboxtwo {
  height: 180px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.zrlskunum {
  font-size: 24px;
  font-family: PingFang;
  font-weight: bold;
  color: #000000;
  letter-spacing: 3px;
}

.zrlallamountbox {
  text-align: right;
  font-size: 24px;
  font-family: PingFang;
  font-weight: bold;
  color: #000000;
}

.zrlmarginleft10 {
  margin-left: 10px;
}

.zrlallamountnum {
  font-size: 30px;
  font-family: Heiti SC;
  font-weight: 500;
  color: #000000;
}

.zrlpad50 {
  font-size: 24px;
  font-family: PingFang;
  font-weight: bold;
  color: #000000;
  //   line-height: 40px;
}

.zrlmaintitle {
  font-size: 30px;
  font-family: PingFang;
  font-weight: bold;
  color: #010101;
  margin-bottom: 10px;
}

.zrldescriptionbox {
  font-size: 24px;
  font-family: PingFang;
  font-weight: 500;
  color: #999999;
  width: 400px;
}

.zrlminhei50 {
  min-height: 50px;
}

.zrlfootbox {
  width: 750px;
  padding-top: 30px;
  padding-bottom: 40px;
  // background: #ffffff;
  position: fixed;
  left:50%;
  transform: translateX(-50%);
  bottom: 0px;
}

.zrlfootmainbox {
  margin: 0 auto;
  width: 690px;
}

.zrlcancelbtn {
  width: 690px;
  height: 80px;
  background: #f9f9f9;
  border: 1px solid #eeeeee;
  text-align: center;
  line-height: 80px;
  font-size: 24px;
  font-family: PingFang;
  font-weight: bold;
  color: #000000;
}

.zrlconfirmbtn {
  width: 330px;
  height: 80px;
  background: #986665;
  text-align: center;
  line-height: 80px;
  font-size: 24px;
  font-family: PingFang;
  font-weight: bold;
  color: #ffffff;
}

.zrlcourierlable {
  font-size: 24px;
  font-family: PingFang;
  font-weight: 500;
  color: #999999;
}

.zrlcouriernoinput {
  width: 630px;
  height: 80px;
  background: #ffffff;
  border: 1px solid #dddddd;
}

.zrlmargintop10 {
  margin-top: 10px;
}

.zrlsetcourierbtn {
  margin: 0 auto;
  width: 690px;
  height: 80px;
  background: #986665;
  text-align: center;
  line-height: 80px;
  font-size: 24px;
  font-family: PingFang;
  font-weight: bold;
  color: #ffffff;
}

.zrlfootzhanwei {
  width: 100%;
  height: 150px;
}

.zrlimgbox {
  width: 50px;
  display: inline-block;
  vertical-align: top;
  margin-right: 5px;
  margin-bottom: 5px;
}
.zrlbox{
    display: none;
  }
@media screen and (min-width: 1000px) {
  .lg{
    display: none;
  }
  .zrlbox{
    display: block;
  }
}
</style>